<template>
  <SharedHeader>
    <template #firstColumn>
      <h1 class='mt-1 h2'>
        <SimpleText text-key='home.header.title'></SimpleText>
      </h1>
      <p>
        <SimpleText text-key='home.header.text'></SimpleText>
      </p>
      <router-link class='button button-primary mr-4' to='/auth' @click='login'>
        <SimpleText text-key='home.header.button1.text'></SimpleText>
      </router-link>
      <a v-if="simpleTextOrNull('home.header.button2.link')"
         :href="simpleTextOrNull('home.header.button2.link')"
         class='button button-secondary' target='_blank' rel="noopener">
        <SimpleText text-key='home.header.button2.text'></SimpleText>
      </a>
    </template>
    <template #secondColumn>
      <img
        v-if="simpleTextOrNull('home.header.image.link')"
        alt=""
        :src="simpleTextOrNull('home.header.image.link')"
      />
    </template>
  </SharedHeader>

  <div class='sections'>
    <div v-if="simpleTextOrNull('home.section-1.title')" class='section'>
      <div class='container px-0' >
        <h3>
          <SimpleText text-key='home.section-1.title'></SimpleText>
        </h3>
        <div class='row mt-705' :class='{"end-row": rowIndex === section1Rows()}' v-for='rowIndex in section1Rows()' :key='rowIndex'>
          <div class='col-6' v-for='colIndex in section1Cols(rowIndex)' :key='colIndex'>
            <div class='section-element'>
                  <span :style='`order: ${colIndex - 1}`'>
                    <img
                      v-if='simpleTextOrNull(`home.section-1.row-${rowIndex}.col-${colIndex}.image.link`)'
                      :src='simpleTextOrNull(`home.section-1.row-${rowIndex}.col-${colIndex}.image.link`)'
                      alt=''
                      style='width: 97px; max-width: initial'
                    />
                  </span>
              <div class='ml-705 custom-rt' style='order: 1'
                   v-html='simpleTextOrNull(`home.section-1.row-${rowIndex}.col-${colIndex}.text`)'></div>
            </div>
          </div>
        </div>
        <div class='row'>
          <div class='col-12'>
            <h4>
              <SimpleText text-key='home.section-1.list.title'></SimpleText>
            </h4>
            <ul class='section2-list'>
              <li v-for='index in 4' :key='index'>
                <svg aria-hidden='true' focusable='false' width='34' height='34'>
                  <use xlink:href='#close-circle'></use>
                </svg>
                <p>
                  <SimpleText :text-key='"home.section-1.list.item-" + index'></SimpleText>
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div v-if="simpleTextOrNull('home.section-2.title')" class='section'>
      <div class='container px-0 py-6'>
        <div class='row'>
          <div class='col-12'>
            <div style='max-width: 1200px; max-height: 676px;'>
              <div class='section3-content'>
                <h2><SimpleText text-key='home.section-2.title'></SimpleText> </h2>
                <ul>
                  <li v-for='itemIndex in section2Items()' :key='itemIndex'>
                    <svg v-if='itemIndex === 1' fill='none' height='44' viewBox='0 0 47 44' width='47' xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M44.9446 22.3369C44.9446 32.9804 35.5802 41.8652 23.6998 41.8652C11.8194 41.8652 2.45508 32.9804 2.45508 22.3369C2.45508 11.6934 11.8194 2.80859 23.6998 2.80859C35.5802 2.80859 44.9446 11.6934 44.9446 22.3369Z'
                        fill='#078856'
                        stroke='#078856'
                        stroke-width='4'
                      />
                      <path
                        d='M30.709 32.0811V29.7311H26.459V14.6311H22.159L17.709 19.4561L19.434 21.0061L23.484 16.6811H23.684V29.7311H18.784V32.0811H30.709Z'
                        fill='white'
                      />
                    </svg>
                    <svg v-if='itemIndex === 2' fill='none' height='45' viewBox='0 0 47 45' width='47' xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M44.9446 22.5166C44.9446 33.1601 35.5802 42.0449 23.6998 42.0449C11.8194 42.0449 2.45508 33.1601 2.45508 22.5166C2.45508 11.8731 11.8194 2.98828 23.6998 2.98828C35.5802 2.98828 44.9446 11.8731 44.9446 22.5166Z'
                        fill='#078856'
                        stroke='#078856'
                        stroke-width='4'
                      />
                      <path
                        d='M30.109 29.8607H21.309L25.409 26.3357C27.709 24.3607 29.584 22.5107 29.584 19.6857C29.584 16.6607 27.534 14.5107 23.984 14.5107C20.484 14.5107 18.734 16.5607 18.009 18.7607L20.484 19.7107C20.909 18.1857 21.834 16.9607 23.759 16.9607C25.484 16.9607 26.584 18.0107 26.584 19.6857V20.0107C26.584 21.6107 25.584 23.0107 23.834 24.5857L18.259 29.5607V32.2607H30.109V29.8607Z'
                        fill='white'
                      />
                    </svg>
                    <svg v-if='itemIndex === 3' fill='none' height='47' viewBox='0 0 47 47' width='47' xmlns='http://www.w3.org/2000/svg'>
                      <circle cx='23.6998' cy='23.3913' fill='#078856' r='21.2448' stroke='#078856' stroke-width='4' />
                      <path
                        d='M21.359 21.5639V23.9639H23.209C25.409 23.9639 26.659 24.9389 26.659 26.7139V26.8889C26.659 28.6889 25.484 29.7639 23.359 29.7639C21.334 29.7639 20.309 28.7889 19.459 27.3889L17.384 29.0139C18.484 30.6139 20.109 32.1389 23.359 32.1389C27.084 32.1389 29.584 30.0889 29.584 27.0389C29.584 24.5139 27.909 23.0889 25.934 22.7139V22.5889C27.759 22.1889 29.284 20.8139 29.284 18.5889C29.284 15.7639 26.909 14.0889 23.609 14.0889C20.709 14.0889 19.109 15.4389 18.059 16.9639L19.934 18.5889C20.734 17.2639 21.884 16.4639 23.534 16.4639C25.209 16.4639 26.309 17.3639 26.309 18.9389V19.1139C26.309 20.5389 25.284 21.5639 23.134 21.5639H21.359Z'
                        fill='white'
                      />
                    </svg>
                    <svg v-if='itemIndex === 4' fill='none' height='47' viewBox='0 0 47 47' width='47' xmlns='http://www.w3.org/2000/svg'>
                      <circle cx='23.6998' cy='23.4967' fill='#078856' r='21.2448' stroke='#078856' stroke-width='4' />
                      <path
                        d='M28.234 31.9443V28.5443H30.609V26.3193H28.234V14.4943H24.534L17.459 26.1193V28.5443H25.559V31.9443H28.234ZM25.384 17.0693H25.559V26.3193H19.859L25.384 17.0693Z'
                        fill='white'
                      />
                    </svg>
                    <svg v-if='itemIndex === 5' fill='none' height='47' viewBox='0 0 47 47' width='47' xmlns='http://www.w3.org/2000/svg'>
                      <circle cx='23.6998' cy='23.6032' fill='#078856' r='21.2448' stroke='#078856' stroke-width='4' />
                      <path
                        d='M29.284 14.6008H19.384L18.734 24.3258L21.059 24.6508C21.684 23.9508 22.509 23.2758 24.009 23.2758C26.059 23.2758 27.234 24.4758 27.234 26.4758V26.7758C27.234 28.7758 26.059 29.9758 24.009 29.9758C22.059 29.9758 21.084 28.9758 20.234 27.6008L18.184 29.2258C19.234 30.8258 20.859 32.3508 23.959 32.3508C27.909 32.3508 30.184 29.8758 30.184 26.4008C30.184 22.9758 27.909 20.9508 24.909 20.9508C22.909 20.9508 22.034 21.8508 21.259 23.2258H21.084L21.534 17.0508H29.284V14.6008Z'
                        fill='white'
                      />
                    </svg>
                    <p><SimpleText :text-key='`home.section-2.list.item-${itemIndex}`'></SimpleText></p>
                  </li>
                </ul>
                <a href=''>
                  <button class='button button-secondary mt-4 px-5' @click='login'>
                    <SimpleText text-key='sharedContent.login' />
                  </button>
                </a>
              </div>
              <svg fill='none' viewBox='0 0 1200 676' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M125.39 393.978C133.929 324.686 210.305 374.994 196.073 292.888C170.597 120.552 344.081 356.01 351.197 297.634C351.197 297.634 223.734 7.47804 374.301 27.6402C504.897 60.8074 395.314 177.559 539.861 53.2687C753.474 -141.848 641.346 290.948 810.733 249.28C942.828 216.852 599.885 37.0505 873.492 1.95686C1077 -24.146 847.876 221.698 1081.75 97.8267C1277.19 1.48246 1183.26 286.17 1131.08 346.043C1078.9 405.916 1150.64 354.586 1183.26 483.203C1209.5 586.613 1036.07 666.455 1036.07 666.455C611.899 666.455 334.927 631.333 334.927 631.333C54.7074 544.427 125.39 393.978 125.39 393.978Z'
                  fill='#EDF6F8'
                />
                <rect fill='white' height='603.186' stroke='black' stroke-width='10' width='762.296' x='290.732'
                      y='61.0615' />
                <path
                  d='M114.721 507.412C119.109 523.057 142.285 608.828 163.324 674.001H195.277C177.501 544.444 160.849 436.273 154.774 371.439L133.51 370.876C133.51 370.989 82.8811 394.401 114.721 507.412Z'
                  fill='#078856'
                  stroke='white'
                  stroke-miterlimit='10'
                  stroke-width='3'
                />
                <path
                  d='M122.258 267.883C122.258 267.883 143.522 269.346 159.499 265.294C160.511 265.069 203.039 311.218 239.717 333.843C239.717 333.843 225.203 352.078 219.916 356.693C219.916 356.693 172.1 340.934 153.086 327.54C145.323 322.024 127.996 285.667 122.258 267.883Z'
                  fill='black'
                  stroke='white'
                  stroke-miterlimit='10'
                  stroke-width='3'
                />
                <path
                  d='M77.2558 372.451L115.846 264.168H133.848C132.947 263.38 166.7 371.663 166.7 371.663L154.774 371.551L77.2558 372.451Z'
                  fill='#078856'
                  stroke='white'
                  stroke-miterlimit='10'
                  stroke-width='3'
                />
                <path
                  d='M154.774 371.438L114.271 507.524C113.821 508.649 74.4427 602.299 33.2647 674L2.99997 672.312C2.99997 672.312 29.1019 598.698 53.9662 532.062C60.2667 515.065 58.9166 371.438 58.9166 371.438H154.774Z'
                  fill='black'
                  stroke='white'
                  stroke-miterlimit='10'
                  stroke-width='3'
                />
                <path
                  d='M86.3691 203.611C87.3817 198.433 91.5445 176.822 93.3446 167.029C94.5822 159.825 84.0064 164.553 72.4181 159.15C60.1547 153.522 53.1791 139.677 52.1666 137.538C44.8535 122.455 48.0037 107.147 49.2413 103.545C54.5292 87.7867 66.2301 79.5698 71.293 76.3055C74.5557 74.2794 98.8575 61.335 122.822 70.2273C127.21 71.9157 145.999 81.821 150.836 94.7654C153.537 101.857 152.074 121.104 144.648 120.317C144.648 120.317 147.461 130.785 147.574 142.041C147.686 148.569 147.686 154.535 144.086 160.388C142.961 162.302 141.498 164.665 138.91 166.129C131.147 170.518 116.296 161.063 116.296 161.063C115.171 162.864 120.009 203.611 120.009 203.611H86.3691Z'
                  fill='black'
                  stroke='white'
                  stroke-miterlimit='10'
                  stroke-width='3'
                />
                <path
                  d='M120.233 202.148C153.423 202.26 183.575 206.65 250.068 189.091C250.068 189.091 255.918 210.477 256.706 218.694C256.706 218.694 196.401 256.514 160.849 264.956C146.223 268.446 137.447 268.671 122.371 267.883C122.371 267.883 126.421 288.932 130.696 323.037C135.984 365.248 125.859 387.084 122.371 392.487C115.62 402.955 109.77 404.981 106.395 405.432C101.557 406.107 47.1028 405.994 37.9896 405.994C29.7765 405.994 31.6892 287.018 80.5178 201.81C80.7428 201.36 86.5932 202.26 86.5932 202.26L120.233 202.148Z'
                  fill='#078856'
                  stroke='white'
                  stroke-miterlimit='10'
                  stroke-width='3'
                />
                <path
                  d='M250.631 190.779C252.093 190.442 255.919 190.104 258.506 188.528C270.207 181.437 279.658 172.319 287.983 170.068C289.334 169.73 291.134 169.843 291.921 170.631C293.159 171.869 291.584 174.683 290.459 175.696C284.271 181.099 276.958 189.654 276.733 190.442C276.395 191.792 278.42 192.355 279.883 193.368C282.021 195.057 285.508 195.169 287.421 195.844C288.434 196.182 289.784 196.857 289.334 197.983C288.096 201.81 276.283 198.771 260.081 203.161C257.944 203.724 255.806 206.875 254.794 207.213L250.631 190.779Z'
                  fill='black'
                  stroke='white'
                  stroke-miterlimit='10'
                  stroke-width='3'
                />
                <path
                  d='M237.805 336.207C251.081 347.576 270.545 347.238 277.971 351.515C279.096 352.191 280.333 353.541 280.333 354.667C280.333 356.355 277.183 357.256 275.72 357.143C267.507 356.58 256.369 357.481 255.581 357.931C254.344 358.606 255.469 360.52 255.694 362.208C256.031 364.91 258.394 367.386 259.294 369.3C259.744 370.313 260.194 371.663 259.182 372.226C255.581 374.027 242.981 356.018 228.354 347.576L237.805 336.207Z'
                  fill='#43A972'
                  stroke='white'
                  stroke-miterlimit='10'
                  stroke-width='3'
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="simpleTextOrNull('home.section-3.title')" class='section'>
      <div class='container px-0'>
        <div class='row'>
          <div class='col-12'>
            <div class='row'>
              <div class='col'>
                <h3><SimpleText text-key='home.section-3.title'></SimpleText></h3>
                <AccordionComponent :accordion-list='getAccordions("home.section-3")' uniqueId='home.accordions'></AccordionComponent>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang='ts'>

import * as DKFDS from 'dkfds'
import SharedHeader from '@/components/calculator/shared/SharedHeader.vue'
import { mixins, Options, Vue } from 'vue-class-component'
import BaseComponent from '@/components/calculator/base-components/BaseComponent'
import SimpleText from '@/components/SimpleText.vue'
import AccordionComponent from '@/components/AccordionComponent.vue'

@Options({
  components: {
    AccordionComponent,
    SimpleText,
    SharedHeader
  }
})
export default class Home extends BaseComponent {

  simpleTextOrNull(textKey: string) {
    return this.store.getters.getContentfulContent.findSimpleTextOrNull(textKey)
  }

  section1Rows() {
    return this.store.getters.getContentfulContent.getNumberOfIterableElements('home.section-1.row-X.col-1.text')
  }

  section1Cols(row: number) {
    return this.store.getters.getContentfulContent.getNumberOfIterableElements(`home.section-1.row-${row}.col-X.text`)
  }

  section2Items() {
    return this.store.getters.getContentfulContent.getNumberOfIterableElements(`home.section-2.list.item-X`)
  }

  mounted() {
    DKFDS.init()

  }

  updated() {
    DKFDS.init()
  }

  async beforeMount() {
    this.setDocumentTitleByKey("title.frontpage")
  }

  async login() {
    await this.store.dispatch('oidcStore/authenticateOidc')
  }
}
</script>

<style lang='scss' scoped>
.accordion-button,
.tabnav > button {
  border: 1px solid #bfbfbf;
}

.section2-list,
.section2-list p {
  max-width: 100% !important;
}

.section2-list p {
  align-self: center;
  margin: 0;
  padding-left: 50px;
}

.section2-list li {
  display: flex;
  padding-left: 0;
}

.section2-list li svg {
  order: 0;
  height: 34px;
  width: 34px;
}

.section2-list li::before {
  content: '';
}

.section3-content {
  position: absolute;
  top: 85px;
  left: 380px;
}

.section3-content li {
  display: flex;
  padding: 0;
  margin-top: 30px;
}

.section3-content li svg {
  order: 0;
  height: 46px;
  width: 46px;
}

.section3-content li p {
  order: 1;
  max-width: 560px !important;
  padding-left: 20px;
  align-self: center;
  margin: 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
}

.section3-content li::before {
  content: '';
}

.section {
  margin: 0;
  padding: 80px 0 50px 0;
  border-bottom: 1px solid #bfbfbf !important;
}

.section:nth-child(even) {
  background-color: #fcfaf8 !important;
}

.section:last-child {
  border-bottom: none !important;
  margin-bottom: -32px;
  padding-bottom: 64px;
}

.section h3 {
  margin-top: 0;
}

.flex-center {
  flex: auto;
}

.d-flex-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 260px;
}

.d-flex-col img {
  height: 110px;
}

.center-items {
  justify-content: space-between;
}

.section-element {
  display: flex;
}


.end-row {
  border-bottom: 2px solid rgb(191, 191, 191);
  padding-bottom: 44px!important;
}
</style>
