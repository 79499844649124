import Toast from "@/components/Toast.vue"
import GenericTableComponentClass from "@/types/component/GenericTableComponentClass"

import _ from "lodash"
import {mixins, Options, Vue} from "vue-class-component"
import {Prop, Watch} from "vue-property-decorator"
import BaseComponent from "@/components/calculator/base-components/BaseComponent"
import CalculatorInputField from "@/types/contentful-types/CalculatorInputField"
import {Calculate, FieldValue, FileMessage} from "openapi/generated-clients/climatecompass"
import GenericTableComponent from "@/components/calculator/CalculatorTableComponent.vue"
import {FormValidation} from "@/shared/FormValidation"
import {calculateClient, countryClient, fileClient} from "@/shared/BackendService"
import * as DKFDS from "dkfds"
import CalculatorPage from "@/types/contentful-types/CalculatorPage"
import {CombinedField} from "@/types/CombinedField"
import ThirdpartyInfo from "@/types/ThirdpartyInfo";
import {Constants} from "@/shared/Constants";


interface SubmitEvent extends Event {
    submitter: HTMLElement;
}

export default class BaseCalculator extends mixins(BaseComponent) {
    @Prop()
    title?: string
    @Prop()
    tooltip?: string
    @Prop()
    expanded?: boolean
    @Prop()
    subgroupingId?: number
    @Prop()
    enableScopeInputs?: boolean
    @Prop()
    calculatorPageSysId?: string
    @Prop({default: false})
    showEFSelection?: boolean
    @Prop({default: false})
    showRFISelection?: boolean

    currentCalculatorPage = {} as CalculatorPage
    contentfulId = ""
    calculationChanged = false
    files: File[] = []
    fileList: Partial<FileMessage[]> = []
    fields: CombinedField[] = []
    renderedFields: CombinedField[] = []
    scopeFields: CombinedField[] = []

    remarksField = {} as CombinedField
    scope3category = {} as CombinedField

    totalEmission = ""
    calculationList: Calculate[] = []
    currentCalculationId? = 0

    genericTableComponentRefs: GenericTableComponent[] = []
    genericTables: GenericTableComponentClass[] = []
    formValidation = new FormValidation()

    showCloseButton = true

    countryEmissionWarningText = ""
    showEmissionWarning = false
    thirdPartyInfo = {} as ThirdpartyInfo

    saveButtonDisable = false

    hasBeenRecalculated = false
    lastSavedCalculationId = 0

    isReload = false

    get isReadOnly() {
        return this.calculation.state === "readonly" || this.calculation.state === "finished_duplicated"
    }

    $refs!: {
        field0: HTMLElement
        formErrorsAlert: HTMLElement
        saveButton: HTMLElement
    }

    @Watch('subgroupingId')
    watchSubgroupingId() {
        this.isReload = true
        this.init()
    }

    async beforeMount() {
        await this.init()
    }

    async init() {
        const countryName = await this.fetchCountryName()
        await this.loadFields().then(async () => {
            this.countryEmissionWarningText = this.countryEmissionWarning.replace("%", countryName ?? "")
        })
            .catch((error) => {
                console.warn("[Advarsel] Indlæsningsfejl: " + error)
            })
        if (this.calculatorPageSysId) {
            const full = await this.getPageFull(this.calculatorPageSysId)
            this.contentfulId = full.sys.id
            this.currentCalculatorPage = full.fields
            //this.currentCalculatorPage = await this.getPage(this.calculatorPageSysId)
        }

        if (this.showEFSelection) {
            this.eventHub.on('ef-updated', async (arg: string) => {
                await this.updatedEmissionFactorToggle(arg)
            })
        }

        if (this.showRFISelection) {
            this.eventHub.on('rfi-updated', async (arg: string) => {
                await this.updatedRfiToggle(arg)
            })
        }
    }

    mounted() {
        DKFDS.init()
        this.eventHub.emit("calculator-mounted")
    }

    updated() {
        DKFDS.init()
        this.eventHub.emit("calculator-mounted")
    }

    setGenericTableComponentRef(comp: any) {
        if (comp) {
            this.genericTableComponentRefs[comp.content.calculateId] = comp
        }
    }

    calculateTotalEmission(calculation: Calculate) {
        if (!calculation.fieldValues) {
            return "0"
        }

        const scopeFields = this.scopeFields
            .filter(x => x.calculatorSlotPosition === Constants.CALCULATOR_SLOT_SCOPE1
                || x.calculatorSlotPosition === Constants.CALCULATOR_SLOT_SCOPE2
                || x.calculatorSlotPosition === Constants.CALCULATOR_SLOT_SCOPE3)
            .map((x: FieldValue) => {
                return x.id
            })

        return calculation.fieldValues
            .filter(x => scopeFields.includes(x.id))
            .reduce((sum, cur) => sum + (!isNaN(Number(cur.value)) ? Number(cur.value) : 0), 0);
    }

    setTotalEmission(val?: string | number) {
        if (val) {
            const prettified = this.doPrettify(val, 2)
            this.totalEmission = prettified ? prettified.toString() : ""
        } else {
            this.totalEmission = val === 0 ? "0,00" : ""
        }
    }

    showToastAndDelayHide(title: string, type: number, cypressIdentifier: number | undefined = undefined) {
        switch (type) {
            case this.constants.TOAST_TYPE_CALCULATOR_DELETE:
                this.addToast("TOAST_TYPE_CALCULATOR_DELETE", 'success',
                    this.deleteRowToastHeader,
                    this.deleteRowToastMessage.replace("#rowname#", title),
                    this.showCloseButton)
                break
            case this.constants.TOAST_TYPE_CALCULATOR_SAVED:
                this.addToast("TOAST_TYPE_CALCULATOR_SAVED", 'success',
                    this.saveRowToastHeader,
                    this.saveRowToastMessage.replace("#rowname#", title),
                    this.showCloseButton)
                break
            case this.constants.TOAST_TYPE_CALCULATOR_UPDATED:
                this.addToast("TOAST_TYPE_CALCULATOR_UPDATED", 'success',
                    this.updateRowToastHeader,
                    this.updateRowToastMessage.replace("#rowname#", title),
                    this.showCloseButton)
                break
            case this.constants.TOAST_TYPE_CALCULATOR_ERROR:
                this.addToast("TOAST_TYPE_CALCULATOR_ERROR", 'error',
                    this.saveErrorToastHeader,
                    this.saveErrorToastMessage.replace("#rowname#", title),
                    this.showCloseButton)
                break
        }
    }

    mapCalculationFieldValues(calculation: Calculate, field: CombinedField) {
        const value = calculation.fieldValues.find((res) => field.id === res.id)?.value || ""

        //Special conditional for biofuel percentage - if field is empty it should be presented as empty rather than 0, as empty means use default value
        if (value === "" && field.id === this.constants.INPUT_BIOBRÆNDSTOFANDEL) {
            return value
        }

        if (field.valueType !== 'STRING') {
            return field.valueType === 'DECIMAL' && field.step === 1 ? String(Math.round(Number(value))) : String(this.formatNumber(value))
        }
        return value
    }

    mapCalculationFieldEnumValues(calculation: Calculate, field: CombinedField) {
        const fieldEnumId = calculation.fieldValues.find((res) => field.fieldEnumId === res.fieldEnumId)?.fieldEnumId || -2
        return fieldEnumId
    }

        getRowColHeaders() {

        return [
            [this.renderedFields[0]?.lable, this.renderedFields[1]?.lable, this.renderedFields[2]?.lable, this.renderedFields[3]?.lable, this.renderedFields[4]?.lable],
            [
                this.scopeFields[0]?.lable,
                this.scopeFields[1]?.lable,
                this.scopeFields[2]?.lable,
                this.totalEmissionsTons,
                this.scopeFields[3]?.lable
            ]
        ]
    }

    getRowColData(calculate: Calculate) {
        return [
            [
                // Don't parse the first, as it is "beskrivelse" can by definition not be a contentful key
                calculate.fieldValues?.find((field: { id: number }) => field.id === this.renderedFields[0]?.id)?.value,
                this.parseFieldNameFromDb(calculate.fieldValues?.find((field: {
                    id: number
                }) => field.id === this.renderedFields[1]?.id)?.value ?? '', ''),
                this.parseFieldNameFromDb(calculate.fieldValues?.find((field: {
                    id: number
                }) => field.id === this.renderedFields[2]?.id)?.value ?? '', ''),
                this.parseFieldNameFromDb(calculate.fieldValues?.find((field: {
                    id: number
                }) => field.id === this.renderedFields[3]?.id)?.value ?? '', ''),
                this.parseFieldNameFromDb(calculate.fieldValues?.find((field: {
                    id: number
                }) => field.id === this.renderedFields[4]?.id)?.value ?? '', '')
            ],
            [
                calculate.fieldValues?.find((field: { id: number }) => field.id === this.scopeFields[0]?.id)?.value,
                calculate.fieldValues?.find((field: { id: number }) => field.id === this.scopeFields[1]?.id)?.value,
                calculate.fieldValues?.find((field: { id: number }) => field.id === this.scopeFields[2]?.id)?.value,
                calculate.fieldValues ? this.calculateTotalEmission(calculate) : "",
                calculate.fieldValues?.find((field: { id: number }) => field.id === this.scopeFields[3]?.id)?.value
            ]
        ]
    }

    get scope3categoryLabel() {
        return this.store.getters.getContentfulContent.findSimpleText('masterdata.scope3categoryLabel')
    }


    calculateToTable(calculate: Calculate, isEdit = false) {
        const s3Selection = this.getS3Selection(calculate)
        const table: GenericTableComponentClass = {
            remarksFieldLabel: this.remarksField.lable,
            remarksFieldValue: calculate.fieldValues?.find((field) => field.id === this.constants.INPUT_BEMÆRKNING)?.value,
            calculationId: calculate.calculationId,
            calculateId: calculate.id,
            readonly: this.isReadOnly,
            fieldValues: calculate.fieldValues,
            isEdit: isEdit,
            createdBy: calculate.createdBy,
            createdTime: calculate.createdTime,
            updatedBy: calculate.updatedBy,
            updatedTime: calculate.updatedTime,
            state: calculate.state,
            scope3CategoryLabel: this.scope3categoryLabel,
            scope3CategorySelection: s3Selection, //this.asContentfulString(s3Selection),
            emcId: calculate.emissionCoefficentId,
            emf: calculate.emissionCoefficentMasterKey,
            information: calculate.information,
            files: calculate.files ? calculate.files : undefined
        }
        if (this.getRowColData(calculate)) {
            table.rowColData = this.getRowColData(calculate)
        }
        if (this.getRowColHeaders()) {
            table.rowColHeaders = this.getRowColHeaders()
        }
        return table
    }

    setGenericTables(targetCalculate?: Calculate, isEdit = false) {
        if (targetCalculate) {
            // Ensure that no other is marked as edit
            this.genericTables.forEach((table: GenericTableComponentClass) => table.isEdit = false)

            const first = targetCalculate.id
            this.genericTables = this.genericTables.sort((a, b) => {
                return a.calculateId === first ? -1 : b.calculateId === first ? 1 : 0
            })
            const editedTable = this.genericTables.find((table) => table.calculateId === first)
            if (editedTable) editedTable.isEdit = isEdit
        } else {
            this.genericTables = []

            const listToShow = [...this.calculationList].sort((a, b) => String(this.getRowColData(a)[0]).localeCompare(String(this.getRowColData(b)[0])))
            for (const calculate of listToShow) {
                this.genericTables.push(this.calculateToTable(calculate))
            }
        }
    }

    updateGenericTable(changedCalculate: Calculate, isDeletion = false) {
        const updatedEntry = this.genericTables.find((table) => table.calculateId == changedCalculate.id)
        if (updatedEntry && !isDeletion) {
            Object.assign(updatedEntry, this.calculateToTable(changedCalculate))
            this.genericTables = this.genericTables.sort((a, b) => String(a.rowColData ? a.rowColData[0] : "").localeCompare(String(b.rowColData ? b.rowColData[0] : "")))
        } else {
            // Is new
            if (isDeletion) {
                const indexToRemove = this.genericTables.findIndex((table) => table.calculateId === changedCalculate.id)
                this.genericTables.splice(indexToRemove, 1)
            } else {
                this.genericTables.push(this.calculateToTable(changedCalculate))
            }
        }
    }

    updateCalculationList(data: any[], fullLoad = false) {
        if (data.length > 0 || this.isReload) {
            this.isReload = false
            const isInitialLoad = this.calculationList.length === 0
            this.calculationList = data.filter((x) => this.calculationList.some((y) => x.id === y.id))
            data.forEach((cal: Calculate) => {
                const existing = this.calculationList.find((x: Calculate) => {
                    return cal.id === x.id
                })

                cal.fieldValues.forEach((field) => {
                    if (field && field.value && typeof field.value === "string" && field.fieldEnumId) {
                        field.value = Constants.FIELD_ENUM_ID_PREFIX + field.fieldEnumId
                        //console.log("UCL - Set field value from fieldenumid + ["+field.value+"]", this.scope3category.id, (field.id == this.scope3category.id), field)
                    }
                })

                if (existing && !_.isEqual(existing, cal)) {
                    Object.assign(existing, cal)
                    this.updateCalculateEntry(cal)
                } else if (!existing) {
                    this.calculationList.push(cal)
                    if (!isInitialLoad) {
                        this.updateCalculateEntry(cal)
                    }
                }

            })

            // Reload all if isInitialLoad - first load
            if (isInitialLoad || fullLoad) this.setGenericTables()
        }
    }

    updateCalculateEntry(calculate: Calculate) {
        const updatedCalculate = this.calculationList.find((calc) => calc.id === calculate.id)
        if (updatedCalculate) {
            // If edited
            Object.assign(updatedCalculate, calculate)
            this.updateGenericTable(updatedCalculate)
        } else {
            //  If new
            this.updateGenericTable(calculate)
        }
    }

    thirdpartyVatNo() {
        const thirdPartyInfoRaw = sessionStorage.getItem('thirdPartyInfo') // context.state.thirdpartyInfo.behalfOfVatNr
        if (thirdPartyInfoRaw) {
            this.thirdPartyInfo = JSON.parse(thirdPartyInfoRaw)
        }
        return this.thirdPartyInfo.behalfOfVatNr ? this.thirdPartyInfo.behalfOfVatNr : 'null'
    }

    async loadCalculations(targetCalculateId = 0) {
        if (!this.calculation.id) return
        await calculateClient
            .getCalculationLines(this.calculation.id, this.subgroupingId ?? 0, this.thirdpartyVatNo())
            .then((res) => {
                if (targetCalculateId > 0) {
                    const updatedCalculate = res.data.find((calc) => calc.id === targetCalculateId)
                    if (updatedCalculate) {
                        updatedCalculate.fieldValues.forEach((field) => {
                            if (field && field.value && typeof field.value === "string" && field.fieldEnumId) {
                                field.value = Constants.FIELD_ENUM_ID_PREFIX + field.fieldEnumId
                                //console.log("UCE - Set field value from fieldenumid + ["+field.value+"]", this.scope3category.id, (field.id == this.scope3category.id), field)
                            }
                        })
                        this.updateCalculateEntry(updatedCalculate)
                    }
                } else {
                    this.updateCalculationList(res.data[0] ? res.data.reverse() : [], true)
                    DKFDS.init()
                }
            })
    }

    async loadFields(targetCalculateId = 0) {
        /*
        const before = await calculateClient.getCalculationFields(Number(this.subgroupingId!))
                for (const item of before.data) {
            if (item.name === 'Scope3_Kategori') {
                            }
        }*/
        await calculateClient
            .getCalculationFields(Number(this.subgroupingId!))
            .then(async (result) => {
                this.fields = result.data.map((field) => {
                    if (field.valueType === "DROPDOWN" || field.valueType === "UNIT") {
                        if (field.possibleValues?.length === 1) {
                            //console.log("loadFields length 1 field_id" + field.id, field.possibleValues[0])
                            return Object.assign(field, {value: Constants.FIELD_ENUM_ID_PREFIX + field.possibleValues[0].id}, {fieldEnumId: field.possibleValues[0].id})
                        } else {
                            // take default value if exist
                            if (field.possibleValues) {
                                for (const item of field.possibleValues) {
                                    if (item.isDefault) {
                                        return Object.assign(field, {value: Constants.FIELD_ENUM_ID_PREFIX + item.id}, {fieldEnumId: field.possibleValues[0].id})
                                    }
                                }
                            }
                            // default
                            return Object.assign(field, {value: ""}, {fieldEnumId: -1})
                        }
                    }
                    // default
                    return Object.assign(field, {value: ""}, {fieldEnumId: undefined})
                })


                for (let field of this.fields) {
                    if (field.contentfulId) {
                        field = Object.assign(field, await this.getPage<CalculatorInputField>(field.contentfulId).catch((error: any) => console.error(error)))
                        field.lable = field.lable?.replaceAll('\\n', '<br />')
                    }
                }
                this.assignFields()

                this.setTotalEmission("")
                await this.loadCalculations(targetCalculateId)
            })
            .catch((error) => {
                console.error(error)
            })
    }

    /***
     * assignFields replaces old index based mapping of fields
     */
    assignFields() {
        //console.log("assignField")
        this.remarksField = this.getFieldByCalculatorPosition(Constants.CALCULATOR_SLOT_BEMAERKNINGER)
        this.scope3category = this.getFieldByCalculatorPosition(Constants.CALCULATOR_SLOT_SCOPE3KATEGORI)
        //console.log("scope3category", this.scope3category)
        if (this.scope3category && this.scope3category.possibleValues) {
            this.scope3category.tooltip = this.asContentfulTooltip(Number(this.subgroupingId!))
        }
        // https://jira.erst.dk/browse/KK2-1627 Numerisk sortering af "streng" fra Contentful
        this.scope3category.possibleValues = this.scope3category.possibleValues?.sort(
            (a, b) => this.getLeadingNumberFromString(a.name) - this.getLeadingNumberFromString(b.name))
        this.scopeFields = [
            this.getFieldByCalculatorPosition(Constants.CALCULATOR_SLOT_SCOPE1),
            this.getFieldByCalculatorPosition(Constants.CALCULATOR_SLOT_SCOPE2),
            this.getFieldByCalculatorPosition(Constants.CALCULATOR_SLOT_SCOPE3),
            // SAMLET er ikke mappet i DB
            this.getFieldByCalculatorPosition(Constants.CALCULATOR_SLOT_UDENFOR)
        ]
        this.renderedFields = [
            this.getFieldByCalculatorPosition(Constants.CALCULATOR_SLOT_BESKRIVELSE),
            this.getFieldByCalculatorPosition(Constants.CALCULATOR_SLOT_FELT2),
            this.getFieldByCalculatorPosition(Constants.CALCULATOR_SLOT_FELT3),
            this.getFieldByCalculatorPosition(Constants.CALCULATOR_SLOT_FELT4),
            this.getFieldByCalculatorPosition(Constants.CALCULATOR_SLOT_FELT5)
        ]
    }

    getLeadingNumberFromString(input: string) {
        try {
            return Number(input.split('.')[0])
        } catch (e) {
            console.warn('[CONTENTFUL] Forkert format af tekststrengen: ' + input)
        }
        return 0
    }

    async editCalculation(calculateId: number) {
        this.currentCalculationId = calculateId
        const _calculation = this.calculationList.find((calculation) => calculation.id === calculateId)

        /*---Clear CSS-class tokens on calculation edit click---*/
        this.formValidation.formErrors.clear()
        document.querySelectorAll(".form-group").forEach(function (element) {
            element.classList.remove('form-error')
        })
        document.querySelectorAll(".form-input").forEach(function (element) {
            element.classList.remove("changed")
        })

        if (_calculation) {
            this.scope3category.value = this.getS3Selection(_calculation)
            const fv = _calculation.fieldValues.find((res) => this.remarksField.id === res.id)
            if (fv) {
                this.remarksField.value = fv!.value
            } else {
                console.error("Ingen fieldValues, noget er galt i backend med data")
            }

            this.scopeFields.forEach((field) => (field.value = this.mapCalculationFieldValues(_calculation, field)))

            this.renderedFields.forEach((field) => {
                field.value = this.mapCalculationFieldValues(_calculation, field)
                // console.log('editCalculation set dependent/values after map, fieldId='+field.id + ' fieldName=' + field.name
                //   + ' fieldValueType=' + field.valueType + ' fieldValue=' + field.value  + ' fieldEnumId=' + field.fieldEnumId)

                //Set dependent
                if (field.possibleValues?.find((e) => (Constants.FIELD_ENUM_ID_PREFIX + e.id) === field.value)) {
                    field.selectedDependents = field.possibleValues?.find((e) => (Constants.FIELD_ENUM_ID_PREFIX + e.id) === field.value)?.dependent
                }

                //Map field enum id to value if present
                if (field.valueType === 'DROPDOWN' || field.valueType === 'DROPDOWN_DEPENDENT' || field.valueType === 'UNIT') {
                    //console.log('editCalculation Move value', field.value, field.fieldEnumId)
                    if (field.value.startsWith(Constants.FIELD_ENUM_ID_PREFIX) && !field.fieldEnumId || field?.fieldEnumId === -1) {
                        field.fieldEnumId = Number(field.value.substring(Constants.FIELD_ENUM_ID_PREFIX.length))
                        // console.log('editCalculation Moved value', field.value, field.fieldEnumId)
                    } else if (!field.value.startsWith(Constants.FIELD_ENUM_ID_PREFIX) && field.fieldEnumId && field?.fieldEnumId !== -1) {
                        field.value = Constants.FIELD_ENUM_ID_PREFIX + field.fieldEnumId
                        // console.log('editCalculation Moved value', field.fieldEnumId, field.value)
                    }
                }

                // console.log('editCalculation set dependent/values end, fieldId='+field.id + ' fieldName=' + field.name
                //   + ' fieldValueType=' + field.valueType + ' fieldValue=' + field.value  + ' fieldEnumId=' + field.fieldEnumId)
            })

            this.setTotalEmission(this.calculateTotalEmission(_calculation))

            this.fileList = (await fileClient.getFilesByCalculationLineId(calculateId!, this.thirdpartyVatNo())).data.files!
        }

        this.setGenericTables(_calculation, true)

        if (this.$refs.field0) {
            const calculator = document.getElementById("calculatorInput" + this.subgroupingId)
            const field0 = document.getElementById(this.$refs.field0.id)
            if (calculator) {
                field0?.focus()
                this.scrollToTargetWithOffset("calculatorInput" + this.subgroupingId)
            }
        }
        //console.log('editCalculation end', calculateId, this.renderedFields)
    }

    getS3Selection(calculation: Calculate) {
        const scope3Selection = calculation.fieldValues.find(x => x.id === this.getFieldByCalculatorPosition(Constants.CALCULATOR_SLOT_SCOPE3KATEGORI).id)?.fieldEnumId;
        if (scope3Selection) {
            return Constants.FIELD_ENUM_ID_PREFIX + scope3Selection
        } else {
            return ''
        }
    }

    async deleteCalculation(calculateLineId?: number) {
        const calculateToDelete = this.calculationList.find((x) => x.id === calculateLineId)
        const calculateToDeleteIndex = this.calculationList.findIndex((x) => x.id === calculateLineId)
        await calculateClient.deleteCalculationLine(calculateLineId!, this.thirdpartyVatNo()).then(async () => {
            if (calculateToDelete) {
                this.updateGenericTable(calculateToDelete, true)
                this.calculationList.splice(calculateToDeleteIndex, 1)
            }
        })
    }

    uploadFiles() {
        if (this.files.length === 0) {
            return []
        }
        return Promise.all(
            this.files.map(async (file) => {
                return await fileClient
                    .storeFile(undefined, file)
                    .then((fileResult) => fileResult.data)
                    .catch(() => {
                        this.formValidation.formErrors.set("file-error", this.fileErrorGeneral)
                        return undefined
                    })
            })
        )
    }

    async updateFiles(calculateLineId: number, files: Array<any>) {
        for (let i = 0; i < files.length; i++) {
            const file: FileMessage = files[i]
            const id = file.fileId
            if (!id) return
            await fileClient.updateCalculationId(id, calculateLineId!, this.thirdpartyVatNo()).catch((error) => {
                this.formValidation.formErrors.set("file-error", this.fileErrorGeneral)
            })
        }
    }

    addFile(e: Event) {
        const element = e.target as HTMLInputElement
        //Reinstantiate arrays if they become undefined after deleting last element
        if (!this.files) {
            this.files = []
        }
        if (!this.fileList) {
            this.fileList = []
        }

        if (this.files.length >= 3) {
            this.formValidation.formErrors.set("file-error", this.fileErrorMaxFiles)
            return
        }

        if (!element.files) return

        this.files.push(element.files[0])
        this.fileList.push({
            fileName: element.files[0].name,
            fileType: element.files[0].type
        })
        element.form?.reset()
    }

    async deleteFile(file: FileMessage) {
        if (file.fileId) {
            await fileClient.deleteFile(file.fileId, this.thirdpartyVatNo())
        }

        this.formValidation.formErrors.delete("file-error")
        this.files.splice(
            this.files.findIndex((e) => e.name === file.fileName),
            1
        )
        this.fileList.splice(
            this.fileList.findIndex((e) => e?.fileName === file.fileName),
            1
        )
    }

    truncate(text: string, maxlength: number) {
        if (text.length <= maxlength) {
            return text
        }
        if (text.length >= maxlength) {
            const fileEnding = text.slice(text.lastIndexOf("."), text.length)
            return text.substring(0, maxlength - fileEnding.length - 3) + "..." + fileEnding
        }
    }

    async updateCalculation(save = false) {
        const fieldValuesArray = this.fields.sort((a, b) => {
            return (a.fieldsOrder ?? 0) - (b.fieldsOrder ?? 0)
        }).map((field) => {
            let enumId = field.fieldEnumId
            if (field && field.value && typeof field.value === "string" && field.value.includes(Constants.FIELD_ENUM_ID_PREFIX)) {
                enumId = parseInt(field.value.substring(Constants.FIELD_ENUM_ID_PREFIX.length))
            }
            return {id: field.id, value: field.value, fieldEnumId: enumId}
        })

        // Don't proceed if some or one of the required fields are empty unless scope fields are enabled
        // Proceed even if Description field is empty
        let skip = this.renderedFields.filter((field) => field.required).some((field) => field.value === "" && field.id !== 1)
        //console.log("updateCalculation, save,skip,fieldValuesArray", save,skip, fieldValuesArray)
        if (this.enableScopeInputs) {
            skip = false
        }

        if (skip) {
            return
        }

        let result: Calculate = {} as Calculate
        let strin = ""
        if (save) {
            //console.log("fieldValuesArray", fieldValuesArray)
            fieldValuesArray.forEach((x, index) => {
                strin += x.id + ",\"" + x.value + "\"" + (index + 1 === fieldValuesArray.length && fieldValuesArray.length === 10 ? ",null,null" : "") + (index + 1 !== fieldValuesArray.length ? "," : "")
            })
            await calculateClient
                .calculateAndSave({
                    id: this.currentCalculationId ? this.currentCalculationId : undefined,
                    calculationId: this.calculation.id,
                    subgroupingId: this.subgroupingId ?? 0,
                    coeficientGroup: this.currentEF,
                    fieldValues: fieldValuesArray,
                    thirdpartyVatNo: this.thirdPartyInfo.behalfOfVatNr,
                    updatedBy: this.thirdPartyInfo.loggedInVatNr,
                    createdBy: this.thirdPartyInfo.loggedInVatNr,
                    usedCountryCode: this.country
                })
                .then((response) => {
                    result = response.data
                    const targetCalculateId = this.currentCalculationId
                    this.lastSavedCalculationId = response.data.id ?? 0 // calculateId
                    this.showToastAndDelayHide(this.renderedFields[0].value, this.currentCalculationId ? this.constants.TOAST_TYPE_CALCULATOR_UPDATED : this.constants.TOAST_TYPE_CALCULATOR_SAVED)

                    this.currentCalculationId = undefined
                    this.renderedFields.forEach((field) => (field.value = ""))
                    this.scopeFields.forEach((field) => (field.value = ""))
                    this.remarksField.value = ""
                    this.resizeRemarksField()
                    this.setTotalEmission("")
                    this.loadFields(targetCalculateId)
                    this.hasBeenRecalculated = false
                })
                .catch((error) => {
                    console.warn("[Advarsel] Indlæsningsfejl: " + error)
                })
        } else {
            if (this.skipCalculate(fieldValuesArray, this.subgroupingId || -1)) {
                //console.log("Skipcalculate")
                return
            }
            await calculateClient
                .calculate({
                    calculationId: this.calculation.id,
                    subgroupingId: this.subgroupingId ?? 0,
                    coeficientGroup: this.currentEF,
                    fieldValues: fieldValuesArray,
                    thirdpartyVatNo: this.thirdPartyInfo.behalfOfVatNr,
                    updatedBy: this.thirdPartyInfo.loggedInVatNr,
                    createdBy: this.thirdPartyInfo.loggedInVatNr
                })
                .then((response) => {
                    this.hasBeenRecalculated = true
                    result = response.data
                    this.updateScopeFields(result)
                    this.setTotalEmission(this.calculateTotalEmission(result))
                    if (this.calculation.companyInformation?.country !== 'DK') {
                        this.showEmissionWarning = result.usedCountryCode === this.calculation.companyInformation?.country
                        // kk2-1260 for foreign own input sections warning should newer be shown
                        if (this.scopeFields[0].valueType.includes('OWN')
                            || this.scopeFields[1].valueType.includes('OWN')
                            || this.scopeFields[2].valueType.includes('OWN')
                            || this.scopeFields[3].valueType.includes('OWN')
                        ) {
                            this.showEmissionWarning = false;
                        }
                    }
                })
                .catch((error) => {
                    console.warn("[Advarsel] Indlæsningsfejl: " + error)
                })
        }
        return result.id
    }

    skipCalculate(fieldValuesArray: Array<FieldValue>, subgroupingId: number) {
        // is there a better way?? do not call BE for the below combinations
        if (fieldValuesArray && fieldValuesArray.length > 3) {
            //console.log("check for skip felt2: '", fieldValuesArray[1].value, "', felt3: '", fieldValuesArray[2].value, "', sg: ", subgroupingId, "slot:", this.getFieldByCalculatorPosition(Constants.CALCULATOR_SLOT_FELT3).value)
            if (fieldValuesArray[3].id === this.constants.INPUT_ENHED_KM_L_KWH && fieldValuesArray[3].value === this.constants.INPUT_ENHED_L) {
                if (fieldValuesArray[1].id === this.constants.INPUT_DROPDOWN_TRANSPORT_DRIVMIDDEL_E &&
                    (fieldValuesArray[1].value.includes('(El)'))) {
                    return true
                }
            }
            const subgroups: Array<number> = [
                this.constants.GROUP_INDKØB_AF_PRODUKTER_I_MONETÆRE_ENHEDER,
                this.constants.GROUP_INDKØB_AF_PRODUKTER_I_MONETÆRE_ENHEDER_II,
                this.constants.GROUP_INDKØB_AF_MATERIALER_I_FYSISKE_ENHEDER,
                this.constants.GROUP_INDKØB_AF_MATERIALER_I_FYSISKE_ENHEDER_II,
                this.constants.GROUP_INDKØB_AF_MATERIALER_I_MONETÆRE_ENHEDER,
                this.constants.GROUP_INDKØB_AF_MATERIALER_I_MONETÆRE_ENHEDER_II]
            if (subgroups.includes(subgroupingId)) {
                if (fieldValuesArray[1].id === this.constants.DROPDOWN_PRODUKT_GRUPPE) {
                    if (fieldValuesArray[2].id === this.constants.DROPDOWN_SPECIFIKKE_PRODUKTER) {
                        if (fieldValuesArray[1].value === "Plast, færdigvarer" && fieldValuesArray[2].value !== "Uspecificeret") {
                            // clear scope3 and sum
                            this.scopeFields[2].value = ''
                            this.totalEmission = ''
                            return true
                        }
                        if (fieldValuesArray[1].value === "Elektronik" && fieldValuesArray[2].value !== "Generelt") {
                            // clear scope3 and sum
                            this.scopeFields[2].value = ''
                            this.totalEmission = ''
                            return true
                        }
                    }
                }
                if (fieldValuesArray[1].id === this.constants.DROPDOWN_MATERIALE_GRUPPE || fieldValuesArray[1].id === this.constants.DROPDOWN_MATERIALE_GRUPPE_II) {
                    if (fieldValuesArray[2].id === this.constants.DROPDOWN_SPECIFIKKE_MATERIALER) {
                        if (fieldValuesArray[1].value === "Tekstil og garn" && fieldValuesArray[2].value !== "Uspecificeret") {
                            // clear scope3 and sum
                            this.scopeFields[2].value = ''
                            this.totalEmission = ''
                            return true
                        }
                    }
                }
            }
        }
        return false
    }

    updateScopeFields(calculate: Calculate) {
        this.scopeFields.forEach((field) => {
            const newScope = calculate.fieldValues?.find((cal) => field.id === cal.id)
            if (newScope)
                Object.assign(field, newScope)
        })
    }

    applyValidationError(element: HTMLInputElement, errortext: string) {
        this.formValidation.formErrors.set(element.id, errortext)
    }

    applyValidationOk(element: HTMLInputElement) {
        this.formValidation.formErrors.delete(element.id)
    }

    async applyConditions(paramValue?: any) {
        //console.error("ApplyConditions", paramValue, "all", this.renderedFields)

        for (const combinedItem of this.renderedFields) {
            const selectedDDvalue = combinedItem.value
            if (combinedItem.possibleValues) {
                for (const item of combinedItem.possibleValues) {
                    // TODO: not enough to take name (e.g. tow drikkevarer in sg14 Fødevarer), we must use id
                    // but then genericInput must be changed
                    if (item.name === selectedDDvalue   && selectedDDvalue !== '') {
                        //console.log("hit:", item, "comb:", combinedItem)
                        combinedItem.fieldEnumId = item.id
                    }
                }
            }
        }
        if (this.renderedFields[1] && this.renderedFields[1].id === this.constants.INPUT_BRAENDSELSTYPE) {
            if (this.renderedFields[1].value !== this.constants.FE_ID_INPUT_ANDEL_BIOGAS) {
                this.renderedFields[2].value = ""
            }
        }

        //Special conditional for E05
        if (this.renderedFields[4] && this.renderedFields[4].id === this.constants.INPUT_BIOBRÆNDSTOFANDEL || (this.constants.FIELD_ENUM_ID_PREFIX+this.renderedFields[4].id) === this.constants.FE_ID_INPUT_ANDEL_BIOGAS) {
            if (this.isDisableBioPart(this.renderedFields[1], this.renderedFields[3])) {
                this.renderedFields[4].value = ""
            }
        }
        await this.updateCalculation(false)
        //Following if statement is necessary to handle form-errors outside submit.
        if(this.formValidation.formErrors) {
            this.focusFirstError(this.formValidation)
        }
    }

    async submitForm(e: Event) {
        const submitter = e as SubmitEvent
        const submitterHtml: string = submitter.submitter.outerHTML
        if (submitterHtml.includes("updateCalculationButton")) {
            await this.updateCalculation(false)
            return
        }

        this.formValidation.formErrors.clear()
        const uploadResult = await this.uploadFiles()
        let continueSubmit = true
        for (const uploadedFile of uploadResult) {
            if (uploadedFile === undefined) {
                // scroll to error
                const element = document.getElementById(`calculator-${this.subgroupingId}-errors`) as HTMLElement

                const headerOffset = 75
                const elementPosition = element.getBoundingClientRect().top
                const offsetPosition = elementPosition + window.pageYOffset - headerOffset

                await this.$nextTick(() => window.scrollTo({top: offsetPosition, behavior: "smooth"}))
                continueSubmit = false
            }
        }
        if (!continueSubmit) {
            return
        }

        let currentCalculateLineId = 0
        this.saveButtonDisable = true
        await this.updateCalculation(true).then(async (response) => {
            currentCalculateLineId = response ?? 0
            if (uploadResult.length > 0) {
                await this.updateFiles(currentCalculateLineId, uploadResult)
            }

            this.files = []
            this.fileList = []
            await this.loadCalculations(currentCalculateLineId)
            this.saveButtonDisable = false
            let filesReload: FileMessage[] | undefined = []
            await fileClient
                .getFilesByCalculationLineId(currentCalculateLineId, this.thirdpartyVatNo())
                .then(async (response) => {
                    filesReload = response.data.files
                    if (this.genericTableComponentRefs[currentCalculateLineId]) await this.genericTableComponentRefs[currentCalculateLineId].getFiles(filesReload)
                })
                .catch((error) => {
                    console.warn(error)
                })
        })
    }

    biofuelInput(e: Event) {
        const element = e.target as HTMLInputElement
        this.renderedFields[4].value = element.value ? element.value : ""
    }

    async setSelectedDependent(fieldId: number, argument: any) {
        //console.log("setSelectedDependent start, fieldId="+fieldId, argument)
        if (this.renderedFields.some((field) => field.id === fieldId)) {
            const field = this.renderedFields?.find((field) => field.id === fieldId)
            if (field && (field.selectedDependents !== argument)) {
                field.selectedDependents = argument
                await this.applyConditions()
            }
        }
    }

    setCalculationChanged() {
        this.calculationChanged = document.getElementsByClassName("form-input changed").length > 0
    }

    async updatedEmissionFactorToggle(arg: string) {
        this.currentEF = arg
        if (this.calculation.id) {
            await calculateClient
                .declarationCalculations(this.calculation.id, arg === "Miljødeklaration", this.thirdpartyVatNo())
                .then(async (result) => {
                    const newCalculation = {
                        ...this.calculation,
                        electricityEnvironmentDeclaration: arg === "Miljødeklaration"
                    }

                    await this.store.dispatch("setCalculation", newCalculation).then(async () => {
                        await this.loadCalculations().then(async () => {
                            await this.updateCalculation(false)
                        })
                    })
                })
                .catch((error) => {
                    console.warn("[Advarsel] Indlæsningsfejl: " + error)
                })
        }
    }

    async updatedRfiToggle(arg: string) {
        if (this.calculation.id) {
            await calculateClient
                .rfiCalculations(this.calculation.id, arg === "includeRfi", this.thirdpartyVatNo())
                .then(() => {
                    const newCalculation = {...this.calculation}
                    newCalculation.rfi = arg === "includeRfi"

                    this.store.dispatch("setCalculation", newCalculation).then(async () => {
                        await this.loadCalculations().then(async () => {
                            await this.updateCalculation(false)
                        })
                    })
                })
                .catch((error) => {
                    console.warn("[Advarsel] Indlæsningsfejl: " + error)
                })
        }
    }

    get constants() {
        return Constants
    }


    asContentfulTooltip(id: number) {
        switch (id) {
            //Energi og processer
            case this.constants.GROUP_FORBRUG_AF_ELEKTRICITET_MED_EGNE_EMISSIONSFAKTORER:
                return this.getContentfulString('calculator.electricity_own_emissionfactors.scope3.category.tooltip')
            case this.constants.GROUP_FORBRUG_AF_ELEKTRICITET:
            case this.constants.GROUP_FJERNVARME:
            case this.constants.GROUP_BRÆNDSLER:
                return this.getContentfulString('calculator.electricity.scope3.category.tooltip')
            case this.constants.GROUP_PROCESUDLEDNING:
                return this.getContentfulString('calculator.process_emission.scope3.category.tooltip')
            case this.constants.GROUP_PROCESUDLEDNING_MED_EGNE_EMISSIONSFAKTORER:
                return this.getContentfulString('calculator.process_emission_own_emissionfactors.scope3.category.tooltip')
            case this.constants.GROUP_VARME_OG_PROCESENERGI_MED_EGNE_EMISSIONSFAKTORER:
                return this.getContentfulString('calculator.heat_process_own_emissionfactors.scope3.category.tooltip')
            case this.constants.GROUP_ANDET_UDLEDNING_VED_ENERGI_OG_PROCESSER:
                return this.getContentfulString('calculator.other_emission_heat_process.scope3.category.tooltip')
            //Indkøb
            case this.constants.GROUP_INDKØB_AF_MATERIALER_I_FYSISKE_ENHEDER:
            case this.constants.GROUP_INDKØB_AF_MATERIALER_I_MONETÆRE_ENHEDER:
            case this.constants.GROUP_INDKØB_AF_MATERIALER_MED_EGNE_EMISSIONSFAKTORER_I_FYSISKE_ENHEDER:
            case this.constants.GROUP_INDKØB_AF_MATERIALER_MED_EGNE_EMISSIONSFAKTORER_I_MONETÆRE_ENHEDER:
                return this.getContentfulString('calculator.purchase.scope3.category.tooltip')
            case this.constants.GROUP_INDKØB_AF_PRODUKTER_I_FYSISKE_ENHEDER:
                return this.getContentfulString('calculator.purchase.scope3.category.tooltip')
            case this.constants.GROUP_INDKØB_AF_PRODUKTER_I_MONETÆRE_ENHEDER:
                return this.getContentfulString('calculator.purchase_products_money_units.scope3.category.tooltip')
            case this.constants.GROUP_INDKØB_AF_PRODUKTER_MED_EGNE_EMISSIONSFAKTORER_I_FYSISKE_ENHEDER:
            case this.constants.GROUP_INDKØB_AF_PRODUKTER_MED_EGNE_EMISSIONSFAKTORER_I_MONETÆRE_ENHEDER:
            case this.constants.GROUP_ANDET_UDLEDNING_VED_PRIMÆRE_INDKØB_I_FYSISKE_ENHEDER:
            case this.constants.GROUP_ANDET_UDLEDNING_VED_PRIMÆRE_INDKØB_I_MONETÆRE_ENHEDER:
                return this.getContentfulString('calculator.purchase_iii.scope3.category.tooltip')
            case this.constants.GROUP_INDKØB_AF_MATERIALER_I_FYSISKE_ENHEDER_II:
            case this.constants.GROUP_INDKØB_AF_MATERIALER_I_MONETÆRE_ENHEDER_II:
            case this.constants.GROUP_INDKØB_AF_MATERIALER_MED_EGNE_EMISSIONSFAKTORER_I_FYSISKE_ENHEDER_II:
            case this.constants.GROUP_INDKØB_AF_MATERIALER_MED_EGNE_EMISSIONSFAKTORER_I_MONETÆRE_ENHEDER_II:
                return this.getContentfulString('calculator.purchase_ii.scope3.category.tooltip')
            case this.constants.GROUP_INDKØB_AF_PRODUKTER_I_FYSISKE_ENHEDER_II:
                return this.getContentfulString('calculator.purchase.scope3.category.tooltip')
            case this.constants.GROUP_INDKØB_AF_PRODUKTER_I_MONETÆRE_ENHEDER_II:
                return this.getContentfulString('calculator.purchase_products_money_units_secondary.scope3.category.tooltip')
            case this.constants.GROUP_INDKØB_AF_PRODUKTER_MED_EGNE_EMISSIONSFAKTORER_I_FYSISKE_ENHEDER_II:
            case this.constants.GROUP_INDKØB_AF_PRODUKTER_MED_EGNE_EMISSIONSFAKTORER_I_MONETÆRE_ENHEDER_II:
            case this.constants.GROUP_ANDET_UDLEDNING_VED_SEKUNDÆRE_INDKØB_I_FYSISKE_ENHEDER:
            case this.constants.GROUP_ANDET_UDLEDNING_VED_SEKUNDÆRE_INDKØB_I_MONETÆRE_ENHEDER:
                return this.getContentfulString('calculator.purchase_iiii.scope3.category.tooltip')
            //Transport
            case this.constants.GROUP_EGNE_OG_LEASEDE_KØRETØJER:
                return this.getContentfulString('calculator.transport_own.scope3.category.tooltip')
            case this.constants.GROUP_FLY_OG_SKIBE:
                return this.getContentfulString('calculator.transport_items.scope3.category.tooltip')
            case this.constants.GROUP_EGNE_OG_LEASEDE_TRANSPORTMIDLER_MED_EGNE_EMISSIONSFAKTORER:
                return this.getContentfulString('calculator.transport_own_own_emissionfactors.scope3.category.tooltip')
            case this.constants.GROUP_PENDLING:
                return this.getContentfulString('calculator.group_pendling.scope3.category.tooltip')
            case this.constants.GROUP_REJSER_I_FORBINDELSE_MED_ARBEJDE_FYSISKE_ENHEDER:
            case this.constants.GROUP_REJSER_I_FORBINDELSE_MED_ARBEJDE_MONETÆRE_ENHEDER_BELØB_I_DKK:
                return this.getContentfulString('calculator.group_travel_work_or_money.scope3.category.tooltip')
            case this.constants.GROUP_MEDARBEJDERTRANSPORT_MED_EGNE_EMISSIONSFAKTORER:
                return this.getContentfulString('calculator.group_employee_transport_own_ef.scope3.category.tooltip')
            case this.constants.GROUP_VARETRANSPORT_I_FYSISKE_ENHEDER:
            case this.constants.GROUP_VARETRANSPORT_I_MONETÆRE_ENHEDER_BELØB_I_DKK:
            case this.constants.GROUP_VARETRANSPORT_TIL_VIRKSOMHED_MED_EGNE_EMISSIONSFAKTORER:
                return this.getContentfulString('calculator.group_product_transport.scope3.category.tooltip')
            case this.constants.GROUP_VARETRANSPORT_I_MONETÆRE_ENHEDER_BELØB_I_DKK_II:
            case this.constants.GROUP_VARETRANSPORT_FRA_VIRKSOMHED_TIL_KUNDE_MED_EGNE_EMISSIONSFAKTORER:
            case this.constants.GROUP_VARETRANSPORT_I_FYSISKE_ENHEDER_II:
                return this.getContentfulString('calculator.group_product_transport_ii.scope3.category.tooltip')
            case this.constants.GROUP_TRANSPORT_ANDET:
                return this.getContentfulString('calculator.group_other_general.scope3.category.tooltip')
            //Solgte produkter
            case this.constants.GROUP_BRUG_AF_SOLGTE_OG_UDLEJEDE_PRODUKTER:
            case this.constants.GROUP_BRUG_AF_SOLGTE_OG_UDLEJEDE_PRODUKTER_MED_EGNE_EMISSIONSFAKTORER:
                return this.getContentfulString('calculator.group_use_of_sold_or_leased_product.scope3.category.tooltip')
            case this.constants.GROUP_ANDET_VED_SOLGTE_PRODUKTER:
                return this.getContentfulString('calculator.group_other_sold_products.scope3.category.tooltip')
            case this.constants.GROUP_FORARBEJDNING_OG_PROCESSERING_AF_SOLGTE_PRODUKTER:
                return this.getContentfulString('calculator.group_sold.scope3.category.tooltip')
            case this.constants.GROUP_END_OF_LIFE_BEHANDLING:
            case this.constants.GROUP_END_OF_LIFE_BEHANDLING_MED_EGNE_EMISSIONSFAKTORER:
                return this.getContentfulString('calculator.group_end_of_life.scope3.category.tooltip')
            //Affald
            case this.constants.GROUP_AFFALD:
            case this.constants.GROUP_ANDET_UDLEDNING_VED_AFFALD_OG_GENBRUG:
                return this.getContentfulString('calculator.group_waste_other_reuse.scope3.category.tooltip')
            case this.constants.GROUP_AFFALD_MED_EGNE_EMISSIONSFAKTORER:
                return this.getContentfulString('calculator.group_waste_own.scope3.category.tooltip')
            default:
                return "Tooltip mangler for id: " + id
        }
    }

    private resizeRemarksField() {
        const remarksFieldElem = document.getElementById(`calculator-${this.subgroupingId}-input-area`)
        if (remarksFieldElem) {
            remarksFieldElem.removeAttribute('style')
        }
    }

    getFieldByCalculatorPosition(position: number) {
        // Alert if there is more than 1 field in the same position, that is enabled
        if (this.fields.filter(x => x.calculatorSlotPosition === position && x.isEnabled).length > 1) {
            console.error('CRITICAL: Der er mere end ét felt der er aktivt på position: ' + position)
        }
        const field = this.fields.find(x => x.calculatorSlotPosition === position && x.isEnabled)
        if (this.fields && field) {
            return field
        }
        return {} as CombinedField
    }

    get totalEmissionsTons() {
        return this.store.getters.getContentfulContent.findSimpleText('calculation.total-emissions.field.label')
    }

    get countryEmissionWarning() {
        return this.store.getters.getContentfulContent.findSimpleText('sharedContent.countryEmissionWarning')
    }

    get deleteRowToastHeader() {
        return this.store.getters.getContentfulContent.findSimpleText('genericcalculation.deleteRowToastHeader')
    }

    get deleteRowToastMessage() {
        return this.store.getters.getContentfulContent.findSimpleText('genericcalculation.deleteRowToastMessage')
    }

    get saveRowToastHeader() {
        return this.store.getters.getContentfulContent.findSimpleText('genericcalculation.saveRowToastHeader')
    }

    get saveRowToastMessage() {
        return this.store.getters.getContentfulContent.findSimpleText('genericcalculation.saveRowToastMessage')
    }

    get updateRowToastHeader() {
        return this.store.getters.getContentfulContent.findSimpleText('genericcalculation.updateRowToastHeader')
    }

    get updateRowToastMessage() {
        return this.store.getters.getContentfulContent.findSimpleText('genericcalculation.updateRowToastMessage')
    }

    get saveErrorToastHeader() {
        return this.store.getters.getContentfulContent.findSimpleText('genericcalculation.saveErrorToastHeader')
    }

    get saveErrorToastMessage() {
        return this.store.getters.getContentfulContent.findSimpleText('genericcalculation.saveErrorToastMessage')
    }

    get fileErrorGeneral() {
        return this.store.getters.getContentfulContent.findSimpleText('genericcalculation.fileErrorGeneral')
    }

    get fileErrorMaxFiles() {
        return this.store.getters.getContentfulContent.findSimpleText('genericcalculation.fileErrorMaxFiles')
    }

}