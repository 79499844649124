import BaseComponent from "@/components/calculator/base-components/BaseComponent";
import {Constants} from "@/shared/Constants";
import {EntryFields} from "contentful";
import Integer = EntryFields.Integer;
import SimpleText from "@/components/SimpleText.vue";
import {Options} from "vue-class-component";

@Options({
    components: {
        SimpleText
    }
})
export default class BaseResults extends BaseComponent {

    async init() {
        //
   }

    publicCategoryTheme(themeName: string) {
        switch (themeName) {
            case Constants.THEME_NAME_ENERGY:         return this.getContentfulString('theme.text.category.energy');
            case Constants.THEME_NAME_PURCHASES:      return this.getContentfulString('theme.text.category.purchase');
            case Constants.THEME_NAME_TRANSPORT:      return this.getContentfulString('theme.text.category.transport');
            case Constants.THEME_NAME_TRASH:          return this.getContentfulString('theme.text.category.waste');
            case Constants.THEME_NAME_SOLDPRODUCTS:   return this.getContentfulString('theme.text.category.soldproducts');
            default:                                  return 'FEJL';
        }
    }

    publicCategoryGroup(groupName: string, themeName: string) {
        switch (groupName) {
            case Constants.GROUP_NAME_EL:                                         return this.getContentfulString('group.text.category.groupelectricity');
            case Constants.GROUP_NAME_VARME_OG_PROCESENERGI:                      return this.getContentfulString('group.text.category.groupheat');
            case Constants.GROUP_NAME_PROCESUDLEDNING:                            return this.getContentfulString('group.text.category.groupprocess');
            case Constants.GROUP_NAME_PRIMARE_INDKOEB_RAAVARER_PRODUKTION:        return this.getContentfulString('group.text.category.groupprimarypurchasesrawmaterials');
            case Constants.GROUP_NAME_INDKOEB_PRODUKTER_OG_SERVICES:              return this.getContentfulString('group.text.category.groupproductsservicesprimary');
            case Constants.GROUP_NAME_EGNE_OG_LEASEDE_TRANSPORTFORM:              return this.getContentfulString('group.text.category.groupownleasedtransports');
            case Constants.GROUP_NAME_MEDARBEJDERTRANSPORT:                       return this.getContentfulString('group.text.category.groupemployeetransport');
            case Constants.GROUP_NAME_VARETRANSPORT_TIL_VIRKSOMHED:               return this.getContentfulString('group.text.category.grouptransporttocompany');
            case Constants.GROUP_NAME_VARETRANSPORT_FRA_VIRKSOMHED_TIL_KUNDEN:    return this.getContentfulString('group.text.category.grouptransporttocustomer');
            case Constants.GROUP_NAME_AFFALD:                                     return this.getContentfulString('group.text.category.grouptrash');
            case Constants.GROUP_NAME_FORARBEJDNING_AF_SOLGTE_PRODUKTER:          return this.getContentfulString('group.text.category.groupprocesssoldproducts');
            case Constants.GROUP_NAME_BRUG_AF_SOLGTE_OG_UDLEJEDE_PRODUKTER:       return this.getContentfulString('group.text.category.groupusedproducts');
            case Constants.GROUP_NAME_END_OF_LIGE_BEHANDLING:                     return this.getContentfulString('group.text.category.groupendoflife');
            case Constants.GROUP_NAME_SEKUNDARE_INDKOEB_HJAELPEMATERIALER_OG_SERVICEYDELSER:
                return this.getContentfulString('group.text.category.groupsecondaryprocurement');
            case Constants.GROUP_NAME_ANDET:
                switch (themeName) {
                    case Constants.THEME_NAME_ENERGY:         return this.getContentfulString('group.text.category.groupheatprocessother');
                    case Constants.THEME_NAME_TRANSPORT:      return this.getContentfulString('group.text.category.groupothertransport');
                    case Constants.THEME_NAME_TRASH:          return this.getContentfulString('group.text.category.groupothertrash');
                    case Constants.THEME_NAME_SOLDPRODUCTS:   return this.getContentfulString('group.text.category.groupothersoldproducts');
                    default:                                  return 'FEJL';
                }
            default:                                      return groupName + '!';
        }
    }

    publicCategoryPurchaseGroup(groupName: string, purchaseGroupName: string) {
        switch (groupName) {
            case Constants.GROUP_NAME_PRIMARE_INDKOEB_RAAVARER_PRODUKTION:
                switch (purchaseGroupName) {
                    case Constants.GROUP_NAME_INDKOEB_MATERIALER:                 return this.getContentfulString('group.text.category.groupmaterialsprimary');
                    case Constants.GROUP_NAME_INDKOEB_PRODUKTER_OG_SERVICES:      return this.getContentfulString('group.text.category.groupproductsservicesprimary');
                    case Constants.GROUP_NAME_ANDET:                              return this.getContentfulString('group.text.category.groupotherprimary');
                    default:                                                      return groupName+' '+purchaseGroupName + ' (' + this.getContentfulString('text.primary') + '!';
                }
            case Constants.GROUP_NAME_SEKUNDARE_INDKOEB_HJAELPEMATERIALER_OG_SERVICEYDELSER:
                switch (purchaseGroupName) {
                    case Constants.GROUP_NAME_INDKOEB_MATERIALER:                  return this.getContentfulString('group.text.category.groupmaterialssecondary');
                    case Constants.GROUP_NAME_INDKOEB_PRODUKTER_OG_SERVICES:       return this.getContentfulString('group.text.category.groupproductsservicessecondary');
                    case Constants.GROUP_NAME_ANDET:                               return this.getContentfulString('group.text.category.groupothersecondary');
                    default:                                                       return groupName+' '+purchaseGroupName + ' (' + this.getContentfulString('text.secondary') + '!';
                }
            default:                                                          return groupName+' '+ purchaseGroupName + '!';
        }
    }

    publicSubCategoryGroup(subGroupId: Integer) {
        switch (subGroupId) {
            case Constants.GROUP_FORBRUG_AF_ELEKTRICITET:                                                           return this.getContentfulString('subcategories.subgroupelectricity');
            case Constants.GROUP_FORBRUG_AF_ELEKTRICITET_MED_EGNE_EMISSIONSFAKTORER:                                return this.getContentfulString('subcategories.subgroup-electricityown');
            case Constants.GROUP_FJERNVARME:                                                                        return this.getContentfulString('subcategories.subgroup-heat');
            case Constants.GROUP_BRÆNDSLER:                                                                         return this.getContentfulString('subcategories.subgroup-fuel');
            case Constants.GROUP_VARME_OG_PROCESENERGI_MED_EGNE_EMISSIONSFAKTORER :                                 return this.getContentfulString('subcategories.subgroup-heatprocessown');
            case Constants.GROUP_PROCESUDLEDNING:                                                                   return this.getContentfulString('subcategories.subgroup-process');
            case Constants.GROUP_PROCESUDLEDNING_MED_EGNE_EMISSIONSFAKTORER:                                        return this.getContentfulString('subcategories.subgroup-processown');
            //case Constants.GROUP_ANDET_UDLEDNING_VED_ENERGI_OG_PROCESSER:                                           return this.getContentfulString('subcategories.subgroup-EnergyProcessOther');
            case Constants.GROUP_INDKØB_AF_MATERIALER_I_FYSISKE_ENHEDER:                                            return this.getContentfulString('subcategories.subgroup-primarypurchasematerialsphysical');
            case Constants.GROUP_INDKØB_AF_MATERIALER_I_MONETÆRE_ENHEDER:                                           return this.getContentfulString('subcategories.subgroup-primarypurchasematerialmonetary');
            case Constants.GROUP_INDKØB_AF_MATERIALER_MED_EGNE_EMISSIONSFAKTORER_I_FYSISKE_ENHEDER:                 return this.getContentfulString('subcategories.subgroup-primarypurchasematerialsphysicalown');
            case Constants.GROUP_INDKØB_AF_MATERIALER_MED_EGNE_EMISSIONSFAKTORER_I_MONETÆRE_ENHEDER:                return this.getContentfulString('subcategories.subgroup-primarypurchasematerialsmonetaryown');
            case Constants.GROUP_INDKØB_AF_PRODUKTER_I_FYSISKE_ENHEDER:                                             return this.getContentfulString('subcategories.subgroup-primarypurchaseproductsphysical');
            case Constants.GROUP_INDKØB_AF_PRODUKTER_I_MONETÆRE_ENHEDER:                                            return this.getContentfulString('subcategories.subgroup-primarypurchaseproductsmonetary');
            case Constants.GROUP_INDKØB_AF_PRODUKTER_MED_EGNE_EMISSIONSFAKTORER_I_FYSISKE_ENHEDER:                  return this.getContentfulString('subcategories.subgroup-primarypurchaseproductsphysicalown');
            case Constants.GROUP_INDKØB_AF_PRODUKTER_MED_EGNE_EMISSIONSFAKTORER_I_MONETÆRE_ENHEDER:                 return this.getContentfulString('subcategories.subgroup-primarypurchaseproductsmonetaryown');
            case Constants.GROUP_ANDET_UDLEDNING_VED_PRIMÆRE_INDKØB_I_FYSISKE_ENHEDER:                              return this.getContentfulString('subcategories.subgroup-primarypurchasephysicalother');
            case Constants.GROUP_ANDET_UDLEDNING_VED_PRIMÆRE_INDKØB_I_MONETÆRE_ENHEDER:                             return this.getContentfulString('subcategories.subgroup-primarypurchasemonetaryother');
            case Constants.GROUP_INDKØB_AF_MATERIALER_I_FYSISKE_ENHEDER_II:                                         return this.getContentfulString('subcategories.subgroup-secpurchasematerialsphysicalunits');
            case Constants.GROUP_INDKØB_AF_MATERIALER_I_MONETÆRE_ENHEDER_II:                                        return this.getContentfulString('subcategories.subgroup-secpurchasematerialsmonetaryunits');
            case Constants.GROUP_INDKØB_AF_MATERIALER_MED_EGNE_EMISSIONSFAKTORER_I_FYSISKE_ENHEDER_II:              return this.getContentfulString('subcategories.subgroup-secpurchasematerialsphysicalown');
            case Constants.GROUP_INDKØB_AF_MATERIALER_MED_EGNE_EMISSIONSFAKTORER_I_MONETÆRE_ENHEDER_II:             return this.getContentfulString('subcategories.subgroup-secpurchasematerialsmonetaryown');
            case Constants.GROUP_INDKØB_AF_PRODUKTER_I_FYSISKE_ENHEDER_II:                                          return this.getContentfulString('subcategories.subgroup-secpurchaseproductsphysical');
            case Constants.GROUP_INDKØB_AF_PRODUKTER_I_MONETÆRE_ENHEDER_II:                                         return this.getContentfulString('subcategories.subgroup-secpurchaseproductsmonetary');
            case Constants.GROUP_INDKØB_AF_PRODUKTER_MED_EGNE_EMISSIONSFAKTORER_I_FYSISKE_ENHEDER_II:               return this.getContentfulString('subcategories.subgroup-secpurchaseproductsphysicalown');
            case Constants.GROUP_INDKØB_AF_PRODUKTER_MED_EGNE_EMISSIONSFAKTORER_I_MONETÆRE_ENHEDER_II:              return this.getContentfulString('subcategories.subgroup-secpurchaseproductsmonetaryown');
            case Constants.GROUP_ANDET_UDLEDNING_VED_SEKUNDÆRE_INDKØB_I_FYSISKE_ENHEDER:                            return this.getContentfulString('subcategories.subgroup-secpurchasephysicalother');
            case Constants.GROUP_ANDET_UDLEDNING_VED_SEKUNDÆRE_INDKØB_I_MONETÆRE_ENHEDER:                           return this.getContentfulString('subcategories.subgroup-secpurchasemonetaryother');
            case Constants.GROUP_AFFALD :                                                                           return this.getContentfulString('subcategories.subgroup-trash');
            case Constants.GROUP_AFFALD_MED_EGNE_EMISSIONSFAKTORER:                                                 return this.getContentfulString('subcategories.subgroup-trashown');
            //case Constants.GROUP_ANDET_UDLEDNING_VED_AFFALD_OG_GENBRUG:                                             return this.getContentfulString('subcategories.subgroup-TrashOther');
            //case Constants.GROUP_FORARBEJDNING_OG_PROCESSERING_AF_SOLGTE_PRODUKTER:                                 return this.getContentfulString('subcategories.subgroup-SoldProductsProcessing');
            case Constants.GROUP_BRUG_AF_SOLGTE_OG_UDLEJEDE_PRODUKTER:                                              return this.getContentfulString('subcategories.subgroup-soldproductsused');
            case Constants.GROUP_BRUG_AF_SOLGTE_OG_UDLEJEDE_PRODUKTER_MED_EGNE_EMISSIONSFAKTORER:                   return this.getContentfulString('subcategories.subgroup-soldproductsown');
            case Constants.GROUP_END_OF_LIFE_BEHANDLING:                                                            return this.getContentfulString('subcategories.subgroup-endoflife');
            case Constants.GROUP_END_OF_LIFE_BEHANDLING_MED_EGNE_EMISSIONSFAKTORER:                                 return this.getContentfulString('subcategories.subgroup-endoflifeown');
            //case Constants.GROUP_ANDET_VED_SOLGTE_PRODUKTER:                                                        return this.getContentfulString('subcategories.subgroup-SoldProductsOther');
            //case Constants.GROUP_FORARBEJDNING_OG_PROCESSERING_AF_SOLGTE_PRODUKTER_MED_EGNE_EMISSIONSFAKTORER:      return this.getContentfulString('subcategories.subgroup-SoldProductsProcessingOwn');
            case Constants.GROUP_EGNE_OG_LEASEDE_KØRETØJER:                                                         return this.getContentfulString('subcategories.subgroup-ownandleasedtransports');
            case Constants.GROUP_FLY_OG_SKIBE:                                                                      return this.getContentfulString('subcategories.subgroup-airplaneship');
            case Constants.GROUP_EGNE_OG_LEASEDE_TRANSPORTMIDLER_MED_EGNE_EMISSIONSFAKTORER:                        return this.getContentfulString('subcategories.subgroup-ownandleasedtransportsown');
            case Constants.GROUP_PENDLING:                                                                          return this.getContentfulString('subcategories.subgroup-pendling');
            case Constants.GROUP_REJSER_I_FORBINDELSE_MED_ARBEJDE_FYSISKE_ENHEDER:                                  return this.getContentfulString('subcategories.subgroup-travelsphysical');
            case Constants.GROUP_REJSER_I_FORBINDELSE_MED_ARBEJDE_MONETÆRE_ENHEDER_BELØB_I_DKK:                     return this.getContentfulString('subcategories.subgroup-travelsmonetary');
            case Constants.GROUP_MEDARBEJDERTRANSPORT_MED_EGNE_EMISSIONSFAKTORER:                                   return this.getContentfulString('subcategories.subgroup-employeetransportown');
            case Constants.GROUP_VARETRANSPORT_I_FYSISKE_ENHEDER:                                                   return this.getContentfulString('subcategories.subgroup-transportphysical');
            case Constants.GROUP_VARETRANSPORT_I_MONETÆRE_ENHEDER_BELØB_I_DKK:                                      return this.getContentfulString('subcategories.subgroup-transportmonetary');
            case Constants.GROUP_VARETRANSPORT_TIL_VIRKSOMHED_MED_EGNE_EMISSIONSFAKTORER:                           return this.getContentfulString('subcategories.subgroup-transporttocompanyown');
            case Constants.GROUP_VARETRANSPORT_I_FYSISKE_ENHEDER_II:                                                return this.getContentfulString('subcategories.subgroup-transporttocustomerphysical');
            case Constants.GROUP_VARETRANSPORT_I_MONETÆRE_ENHEDER_BELØB_I_DKK_II:                                   return this.getContentfulString('subcategories.subgroup-transporttocustomermonetary');
            case Constants.GROUP_VARETRANSPORT_FRA_VIRKSOMHED_TIL_KUNDE_MED_EGNE_EMISSIONSFAKTORER:                 return this.getContentfulString('subcategories.subgroup-transporttocustomerown');
            case Constants.GROUP_TRANSPORT_ANDET:                                                                             return this.getContentfulString('subcategories.subgroup-other');
            default:                                                                                                return 'FEJL: '+subGroupId.toString();
        }
    }

}
