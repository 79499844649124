import store, { State } from '.'

export const isAuthenticated = () : boolean => {
  return store.getters['oidcStore/oidcIsAuthenticated']
}

export const hasConsented = (state: State) => {
  return state.hasConsented
}

export const getLocale = () => {
  // returns the locale string from local storage if it is present, otherwise returns the default locale
  return window.localStorage.getItem('locale') || 'da';
}

export const getUserClientLoadTimestamp = (state: State) => {
  return state.userClientLoadTimestamp
}

export const isPrivateUser = (state: State) => {
  return state.currentUser.profile ? state.currentUser.profile['dk:gov:cvr'] === undefined : false
}

export const getCvrCertificate = (state: State) => {
  return state.currentUser.profile ? state.currentUser.profile['dk:gov:cvr'] : null
}

export const getContactPerson = (state: State) => {
  return state.contactPerson
}

export const getCurrentUser = (state: State) => {
  return state.currentUser
}

export const getThirdpartyInfo = (state: State) => {
  return state.thirdpartyInfo
}

export const getCurrentCalculationPageData = (state: State) => {
  const sessionCurrentCalculationPageData = window.sessionStorage.getItem('currentCalculationPageData') || null
  if (sessionCurrentCalculationPageData) {
    store.dispatch('setCurrentCalculationPageData', JSON.parse(sessionCurrentCalculationPageData))
  }
  return state.currentCalculationPageData
}

export const getCurrentCalculation = (state: State) => {
  const sessionCurrentCalculation = window.sessionStorage.getItem('currentCalculation') || ''
  if (sessionCurrentCalculation !== '' && !state.currentCalculation.calculationPurpose.name) {
    store.dispatch('setCurrentCalculation', JSON.parse(sessionCurrentCalculation))
  }
  return state.currentCalculation
}

export const getCurrentForecastInfo = (state: State) => {
  const sessionCurrentForecastInfo = window.sessionStorage.getItem('currentForecastInfo') || ''
  if (sessionCurrentForecastInfo !== '' && !state.currentForecastInfo.name) {
    store.dispatch('setCurrentForecastInfo', JSON.parse(sessionCurrentForecastInfo))
  }
  return state.currentForecastInfo
}

export const getCurrentAccess = (state: State) => {
  const sessionCurrentAccess = window.sessionStorage.getItem('currentAccess') || ''
  if (sessionCurrentAccess !== '' && !state.currentAccess.contantPersonName) {
    store.dispatch('setCurrentAccess', JSON.parse(sessionCurrentAccess))
  }
  return state.currentAccess
}

export const getCalculations = (state: State) => {
  return state.calculations
}

export const getStatistics = (state: State) => {
  return state.statistics
}

export const getEmfMasterdataList = (state: State) => {
  return state.emfMasterdataList
}

export const getExcelMappingList = (state: State) => {
  return state.excelMappingList
}

export const getForecasts = (state: State) => {
  return state.forecasts
}

export const getPendingForecasts = (state: State) => {
  return state.pendingForecasts
}

export const getScopeFields = (state: State) => {
  return state.scopeFields
}

export const getAccesses = (state: State) => {
  return state.accesses
}

export const getGivenAccesses = (state: State) => {
  return state.givenAccesses
}

export const accessTokenExpiring = (state: State) => {
  return state.accessTokenExpiring
}

export const returnUrlSessionRefresh = (state: State) => {
  return window.sessionStorage.getItem("returnUrlSessionRefresh") || ""
};

export const getContentfulEntries = (state: State) => {
  return state.contentfulEntries
}

export const getContentfulContent = (state: State) => {
  return state.contentfulContent
}

export const getRegexPatterns = (state: State) => {
  return state.regexPatterns
}

export const getMenuStructure = (state: State) => {
  return state.menuStructure
}
