<template>
  <Chart
      v-if="chartOptions.series && chartOptions.series.length > 0 "
      ref="scope3CategoryChart"
      width="100%"
      height="400"
      type="bar"
      :options="chartOptions"
      :series="chartOptions.series">
  </Chart>
</template>

<script lang="ts">

import BaseComponent from "@/components/calculator/base-components/BaseComponent";
import VueApexCharts, {VueApexChartsComponent} from 'vue3-apexcharts'
import {Options} from "vue-class-component";
import {CategoryResult} from "openapi/generated-clients/climatecompass"
import {Prop, Watch} from "vue-property-decorator";
import {Constants} from "@/shared/Constants";
import SimpleText from "@/components/SimpleText.vue";


@Options({
  components: {
    Chart: VueApexCharts,
    SimpleText,
  }
})


export default class Scope3ResultsColumnChart extends BaseComponent {
  @Prop()
  rawData?: CategoryResult[]
  upstreamCategories = ['CATEGORY_1', 'CATEGORY_2', 'CATEGORY_3', 'CATEGORY_4', 'CATEGORY_5', 'CATEGORY_6', 'CATEGORY_7']
  downstreamCategories = ['CATEGORY_9', 'CATEGORY_10', 'CATEGORY_11', 'CATEGORY_12', 'CATEGORY_13',]

  chartOptions: {
    series: any[]
  } = { series: [] }

  @Watch('rawData')
  rawDataWatch() {
    if(this.chartOptions.series && this.chartOptions.series.length > 0 ) {
      this.chartOptions = this.getChart()
      this.$refs.scope3CategoryChart.updateOptions(this.chartOptions)
    }
  }

  $refs!: {
    scope3CategoryChart: VueApexChartsComponent
  }

  /* JSON input data for chart customization.
  for reference, visit docs at: https://apexcharts.com/docs/  */
  options = {
    tooltip: {
      theme: 'light',
      marker: {
        show: false
      },
      intersect: false,
      y: {
        formatter: function(value: any) {
          return value
        },
        title: {
          formatter: function(seriesName: any) {
            seriesName = 'Eksakt værdi i ton CO2e:'
            return seriesName
          }
        }
      }
    },
    plotOptions: {
      bar: {
        borderRadius: 2,
        columnWidth: '40%',
        dataLabels: {
          position: 'top',
          hideOverFlowingLabels: false
        }
      }
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ['#333'],
        fontSize: '10px',
        fontFamily: 'Helvetica, Arial, sans-serif',
      },
      formatter: function(value: string) {
        return parseFloat(value).toFixed(2);
      },
      hideOverFlowingLabels: false,
      offsetY: -17,
    },
    yaxis: {
      decimalsInFloat: 2,
    },
    legend: {
      position: 'bottom',
    },
    chart: {
      fontFamily: 'IBM Plex Sans, system',
      parentHeightOffset: 0,
      type: 'bar',
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350
        }
      }
    },
    grid: {
      borderColor: '#D3D3D3',
      strokeDashArray: 7,
    },
    colors: [
      function({dataPointIndex, w}: any) {
        if (!w.config.series[0].data[dataPointIndex]) {
          return '#078856'
        }
        const name = w.config.series[0].data[dataPointIndex].x[0]
        const categoryNumber = name.split('.')[0]
        if(categoryNumber && name) {
          if (categoryNumber > 8) {
            return '#078856'
          } else {
            return '#00EE9A'
          }
        }
      }
    ]
  }

  async beforeMount() {
    if (this.rawData) {
      this.chartOptions = this.getChart()
    }
  }

  getChart() {
    if (!this.rawData) {
      return
    }

    const newOptions = [] as any
    const scope315Series = [] as any
    const refinedCategories: (string[] | string)[] = []

    this.rawData.forEach((scope3Result: CategoryResult) => {
      scope315Series.push({x: this.asContentfulString(scope3Result.categoryName ?? '').split(','), y: scope3Result.categoryResult})
      refinedCategories.push(this.categoryStringFormatter(this.asContentfulString(scope3Result.categoryName ?? '')))
    })
     newOptions.series = [{
       data: scope315Series
     },]


    newOptions.xaxis = {
      type: 'category',
      categories: refinedCategories
    }

    return Object.assign(this.options, newOptions)
  }

  asContentfulString(value: string) {
    if (value === Constants.SCOPE3_CATEGORY_1) {
      return this.getContentfulString('calculation.dropdown.possiblevalue.unit.category1')
    } else if (value === Constants.SCOPE3_CATEGORY_2) {
      return this.getContentfulString('calculation.dropdown.possiblevalue.unit.category2')
    } else if (value === Constants.SCOPE3_CATEGORY_3) {
      return this.getContentfulString('calculation.dropdown.possiblevalue.unit.category3')
    } else if (value === Constants.SCOPE3_CATEGORY_4) {
      return this.getContentfulString('calculation.dropdown.possiblevalue.unit.category4')
    } else if (value === Constants.SCOPE3_CATEGORY_5) {
      return this.getContentfulString('calculation.dropdown.possiblevalue.unit.category5')
    } else if (value === Constants.SCOPE3_CATEGORY_6) {
      return this.getContentfulString('calculation.dropdown.possiblevalue.unit.category6')
    } else if (value === Constants.SCOPE3_CATEGORY_7) {
      return this.getContentfulString('calculation.dropdown.possiblevalue.unit.category7')
    } else if (value === Constants.SCOPE3_CATEGORY_8) {
      return this.getContentfulString('calculation.dropdown.possiblevalue.unit.category8')
    } else if (value === Constants.SCOPE3_CATEGORY_9) {
      return this.getContentfulString('calculation.dropdown.possiblevalue.unit.category9')
    } else if (value === Constants.SCOPE3_CATEGORY_10) {
      return this.getContentfulString('calculation.dropdown.possiblevalue.unit.category10')
    } else if (value === Constants.SCOPE3_CATEGORY_11) {
      return this.getContentfulString('calculation.dropdown.possiblevalue.unit.category11')
    } else if (value === Constants.SCOPE3_CATEGORY_12) {
      return this.getContentfulString('calculation.dropdown.possiblevalue.unit.category12')
    } else if (value === Constants.SCOPE3_CATEGORY_13) {
      return this.getContentfulString('calculation.dropdown.possiblevalue.unit.category13')
    } else if (value === Constants.SCOPE3_CATEGORY_14) {
      return this.getContentfulString('calculation.dropdown.possiblevalue.unit.category14')
    } else if (value === Constants.SCOPE3_CATEGORY_15) {
      return this.getContentfulString('calculation.dropdown.possiblevalue.unit.category15')
    } else {
      // default just return input
      return value
    }
  }
}

</script>