import dayjs from 'dayjs'

export const formatDates = (startDate: string, endDate: string) => {
  const _startDate = dayjs(startDate)
  // there is a difference in dayjs for chrome and firebox. endDate is like '10-10' no year so the old dayjs method failed
  // with invalid date in firefox
  // let _endDate = dayjs(endDate );
  //_endDate = _endDate.set("year", _startDate.year());
  const _endDate = dayjs(+_startDate.year() + '-' + endDate)

  return `${_startDate.format('DD/MM/YYYY')} &ndash; ${_endDate.format('DD/MM/YYYY')}`
}

export const formatDate = (dd: string) => {
  const _dDate = dayjs(dd)

  return `${_dDate.format('DD/MM/YYYY')}`
}
