import {AccessInfo, Calculation, ContactPerson, ForecastInfo} from 'openapi/generated-clients/climatecompass'
import {
    accessClient,
    calculationClient,
    consentClient,
    contactPersonClient,
    emissionsfactorImportClient,
    forecastClient,
    sessionClient,
    statisticsClient
} from '@/shared/BackendService'
import {ActionTree} from 'vuex'
import {State} from '.'
import {User} from 'oidc-client'
import {getCurrentUser} from "@/store/getters";
import ThirdpartyInfo from "@/types/ThirdpartyInfo";
import ContentfulContent from "@/shared/ContentfulContent";
import {MenuStructure} from "@/shared/MenuStructure";
import Toast from "@/components/Toast.vue";
import ToastMessage from "@/types/ToastMessage";
import {PendingForecastEntry} from "@/types/PendingForecastEntry";

const profileCVR = 'dk:gov:cvr'
const profileCPR = 'dk:gov:cpr'
const orgName = 'dk:gov:org_name'

const actions: ActionTree<State, any> = {
    logout(context) {
        context.dispatch('clearCurrentCalculation')

        window.sessionStorage.setItem('vatNo', '')
        window.sessionStorage.setItem('thirdPartyInfo', '')
        window.sessionStorage.setItem('currentCalculationPageData', '')

        //Clear forecast polling and info
        context.dispatch('clearPendingForecast')
        context.dispatch('clearCurrentForecastInfo')

        context.commit('logout')
        //sessionClient.logout().then((res) =>
        //    console.log("logout:", res)
        //)
    },

    login(context, user: User) {
        window.sessionStorage.setItem('vatNo', user.profile[profileCVR] || user.profile[profileCPR])

        context.commit('login', user)
    },

    accessTokenExpiring(context) {
        context.commit('accessTokenExpiring', true)
    },

    accessTokenExpired(context) {
        context.commit('accessTokenExpiring', false)
    },

    setUserClientLoadTimestamp(context, version) {
        context.commit('setUserClientLoadTimestamp', version)
    },

    setReturnUrlSessionRefresh(context, value: boolean) {
        window.sessionStorage.setItem('returnUrlSessionRefresh', `${value}`)
        context.commit('setReturnUrlSessionRefresh', value)
    },

    async loadConsent(context) {
        const consent = (await consentClient.getConsent()).data
        context.commit('setConsent', consent)
    },

    async setConsent(context) {
        await consentClient.setConsent()
        context.commit('setConsent', true)
    },

    getLocale() {
        // returns the locale string from session storage if it is present, otherwise returns the default locale
        return window.localStorage.getItem('locale') || 'da';
    },

    setLocale(context, locale) {
        window.localStorage.setItem('locale', `${locale}`);
        context.commit("setSessionLocale", locale);
    },

    setUseTextKeys(context, useTextKeys: boolean) {
        window.sessionStorage.setItem('useTextKeys', `${useTextKeys}`);
        context.commit("setUseTextKeys", useTextKeys);
    },

    async setContactPerson(context, contactPerson: ContactPerson) {
        contactPerson.vatNo = context.state.currentUser.profile[profileCVR] || context.state.currentUser.profile[profileCPR]

        try {
            await contactPersonClient.createContactPerson(contactPerson)
        } catch (error : any) {
            if (error.response.status === 403) {
                await contactPersonClient.updateContactPerson(contactPerson)
            } else {
                console.error(error)
            }
        }

        context.commit('setContactPerson', contactPerson)
    },

    async setCurrentCalculationPageData(context, calculatorPageData: any) {
        try {
            window.sessionStorage.setItem('currentCalculationPageData', JSON.stringify(calculatorPageData))
            context.commit('setCurrentCalculationPageData', calculatorPageData)
        } catch (e) {
            console.error(e)
        }
    },
    async setCalculation(context, calculation: Calculation) {
        if (!calculation.contactPersonRID) {
            calculation.contactPersonRID = context.state.currentUser.profile.sub
        }
        if (!calculation.vatNo) {
            calculation.vatNo = context.state.currentUser.profile[profileCVR] || context.state.currentUser.profile[profileCPR]
        }
        calculation.behalfOfVatNo = (context.state.thirdpartyInfo.behalfOfVatNr ? context.state.thirdpartyInfo.behalfOfVatNr : 'null')

                if (calculation.companyInformation?.country !== 'DK') {
                        calculation.electricityEnvironmentDeclaration = true
        }
        
        try {
            if (calculation.id) {
                await calculationClient.updateCalculation(calculation).then((res) => context.dispatch('setCurrentCalculation', res.data))
            } else {
                await calculationClient.createCalculation(calculation).then((res) => context.dispatch('setCurrentCalculation', res.data))
            }
        } catch (error) {
            console.error(error)
        }
    },
    async copyCalculation(context, calculation: Calculation) {
        if (!calculation.contactPersonRID) {
            calculation.contactPersonRID = context.state.currentUser.profile.sub
        }
        if (!calculation.vatNo) {
            calculation.vatNo = context.state.currentUser.profile[profileCVR] || context.state.currentUser.profile[profileCPR]
        }
        calculation.behalfOfVatNo = (context.state.thirdpartyInfo.behalfOfVatNr ? context.state.thirdpartyInfo.behalfOfVatNr : 'null')

        try {
            await calculationClient.copyCalculation(calculation).then((res) => context.dispatch('copyCurrentCalculation', res.data))
        } catch (error) {
            console.error(error)
        }
    },
    async loadContactPerson(context) {
        if (!context.state.currentUser.profile) {
            return
        }
        try {
            const data = await contactPersonClient.fetchContactPerson(
                context.state.currentUser.profile[profileCVR] || context.state.currentUser.profile[profileCPR]
            )
            context.commit('setContactPerson', data.data)
        } catch (error) {
            console.error(error)
        }
    },
    async loadCalculations(context) {
        try {
            await context.dispatch('loadContactPerson')
            let thirdpartyVatNo = context.state.thirdpartyInfo.behalfOfVatNr
            if (!thirdpartyVatNo) {
                thirdpartyVatNo = 'null'
            }
            let vatNo = context.state.contactPerson.vatNo
            if (!vatNo) {
                vatNo = context.state.currentUser.profile[profileCVR] || context.state.currentUser.profile[profileCPR]
            }
            console.log("loadCal", vatNo)
            await calculationClient.getCalculations(
                vatNo,
                context.state.currentUser.profile.sub,
                thirdpartyVatNo).then((response) => {
                context.commit('setCalculations', response.data.calculations)
            })
        } catch (error) {
            console.error(error)
        }
    },
    async loadCalculationsForMove(context, vatNoOrCpr : string) {
        try {
            await calculationClient.getCalculations(
                vatNoOrCpr,
                context.state.currentUser.profile.sub,
                'null').then((response) => {
                context.commit('setCalculations', response.data.calculations)
            })
        } catch (error) {
            console.error(error)
        }
    },
    async loadStatistics(context) {
        try {
            await statisticsClient.getStatistics().then((response) => {
                context.commit('setStatistics', response.data)
            })
        } catch (error) {
            console.error(error)
        }
    },
    async loadEMFMasterdataList(context) {
        try {
            console.log("loadEMFMasterdataList", context)
            const emissionCoefficientVersionId = 1
            await emissionsfactorImportClient.getEMFMasterdata(emissionCoefficientVersionId).then((response) => {
                context.commit('setEMFMasterdata', response.data)
            })
        } catch (error) {
            console.error(error)
        }
    },
    async loadExcelMappingList(context) {
        try {
            await emissionsfactorImportClient.getExcelMappingdata().then((response) => {
                context.commit('setExcelMappingdata', response.data)
            })
        } catch (error) {
            console.error(error)
        }
    },
    async loadForecasts(context) {
        try {
            await context.dispatch('loadContactPerson')
            let thirdpartyVatNo = context.state.thirdpartyInfo.behalfOfVatNr
            if (!thirdpartyVatNo) {
                thirdpartyVatNo = 'null'
            }
            await forecastClient.getForecasts(
                context.state.contactPerson.vatNo,
                context.state.currentUser.profile.sub,
                thirdpartyVatNo).then((response) => {
                context.commit('setForecasts', response.data.forecasts)
            })
        } catch (error) {
            console.error(error)
        }
    },
    async deleteForecast(context, forecastInfo: ForecastInfo) {
        try {
            let thirdpartyVatNo = context.state.thirdpartyInfo.behalfOfVatNr
            if (!thirdpartyVatNo) {
                thirdpartyVatNo = 'null'
            }
            if (forecastInfo.forecastId) {
                await forecastClient.deleteForecast(forecastInfo.forecastId,thirdpartyVatNo).then((res) => context.dispatch('setCurrentForecastInfo', res.data))
            }
        } catch (error) {
            console.error(error)
        }
    },

    async loadAccesses(context) {
        try {
            await context.dispatch('loadContactPerson')
            let vatNo = context.state.contactPerson.vatNo
            if (!vatNo) {
                vatNo = context.state.currentUser.profile[profileCVR] || context.state.currentUser.profile[profileCPR]
            }
            await accessClient.getAccess(vatNo).then((response) => {
                context.commit('setAccesses', response.data)
            })
        } catch (error) {
            console.error(error)
        }
    },
    async loadGivenAccesses(context) {
        try {
            await context.dispatch('loadContactPerson')
            let vatNo = context.state.contactPerson.vatNo
            if (!vatNo) {
                vatNo = context.state.currentUser.profile[profileCVR] || context.state.currentUser.profile[profileCPR]
            }
            console.log("loadGivenAccesses2, vatnr", vatNo, "cp:", context.state.contactPerson.vatNo, "profile:", context.state.currentUser.profile[profileCVR])
            await accessClient.getGivenAccess(context.state.currentUser.profile[profileCVR]).then((response) => {
                context.commit('setGivenAccesses', response.data)
            })
        } catch (error) {
            console.error(error)
        }
    },

    setCurrentCalculation(context, calculation: Calculation) {
        window.sessionStorage.setItem('currentCalculation', JSON.stringify(calculation))
        context.commit('setCurrentCalculation', calculation)
    },
    setCurrentForecastInfo(context, forecastInfo: ForecastInfo) {
        window.sessionStorage.setItem('currentforecastInfo', JSON.stringify(forecastInfo))
        context.commit('setCurrentForecastInfo', forecastInfo)
    },
    addToPendingForecasts(context, pendingForecast: PendingForecastEntry) {
        if(window.sessionStorage.getItem('currentPendingForecasts') !== null) {
            const pendingForecastArray = JSON.parse(window.sessionStorage.getItem('currentPendingForecasts') ?? '[]') as PendingForecastEntry[]
            if(!pendingForecastArray.some(x => x.forecastId === pendingForecast.forecastId)) {
                pendingForecastArray.push(pendingForecast)
                window.sessionStorage.setItem('currentPendingForecasts', JSON.stringify(pendingForecastArray))
                context.commit("setPendingForecasts", pendingForecastArray)
            }
        } else {
            window.sessionStorage.setItem('currentPendingForecasts', JSON.stringify([pendingForecast]))
            context.commit("setPendingForecasts", [pendingForecast] as PendingForecastEntry[])
        }
    },
    updatePendingForecast(context, forecast: PendingForecastEntry) {
        const pendingForecastArray = JSON.parse(window.sessionStorage.getItem('currentPendingForecasts')  ?? '[]') as PendingForecastEntry[]
        if (pendingForecastArray && pendingForecastArray.some(x => x.forecastId === forecast.forecastId)) {
            const index = pendingForecastArray.findIndex(x => x.forecastId === forecast.forecastId)
            pendingForecastArray[index] = forecast
            window.sessionStorage.setItem('currentPendingForecasts', JSON.stringify(pendingForecastArray))
            context.commit("setPendingForecasts", pendingForecastArray)
        } else {
            window.sessionStorage.setItem('currentPendingForecasts', JSON.stringify([forecast]))
            context.commit("setPendingForecasts", [forecast] as PendingForecastEntry[])
        }
    },
    clearPendingForecast(context) {
        window.sessionStorage.removeItem('currentPendingForecasts')
        context.commit('setPendingForecasts', [])
    },
    clearCurrentCalculation(context) {
        window.sessionStorage.setItem("currentCalculation", "")
        context.commit("setCurrentCalculation", {} as Calculation)
    },
    clearCurrentForecastInfo(context) {
        window.sessionStorage.setItem("currentForecastInfo", "")
        context.commit("setCurrentForecastInfo", {} as ForecastInfo)
    },
    setContentfulEntries(context, contentfulEntries: Map<string, object>) {
        context.commit("setContentfulEntries", contentfulEntries)
    },
    setContentfulContent(context, contentfulContent: ContentfulContent) {
        context.commit("setContentfulContent", contentfulContent)
    },
    setRegexPatterns(context, regexPatterns: Map<string, string>) {
        context.commit("setRegexPatterns", regexPatterns)
    },
    setMenuStructure(context, menuStructure: MenuStructure) {
        context.commit("setMenuStructure", menuStructure)
    },
    async setAccess(context, accessInfo: AccessInfo) {
        accessInfo.vatIdSource = context.state.currentUser.profile[profileCVR] || context.state.currentUser.profile[profileCPR]
        accessInfo.nameSource = context.state.currentUser.profile['name']
        accessInfo.companyNameSource = context.state.currentUser.profile[orgName]
        if (accessInfo.id) {
            await accessClient.updateAccess(accessInfo)
        } else {
            await accessClient.createAccess(accessInfo)
        }
    },
    async deleteAccess(context, accessInfo: AccessInfo) {
        try {
            if (accessInfo.id) {
                await accessClient.deleteAccess(accessInfo.id)
            }
        } catch (error) {
            console.error(error)
        }
    },
    setCurrentAccess(context, access: AccessInfo) {
        window.sessionStorage.setItem('currentAccess', JSON.stringify(access))
        context.commit('setCurrentAccess', access)
    },
    clearCurrentAccess(context) {
        window.sessionStorage.setItem("currentAccess", "")
        context.commit("setCurrentAccess", {} as AccessInfo)
    },
    setThirdPartyInfo(context, thirdpartyInfo: ThirdpartyInfo) {
        //window.sessionStorage.setItem('vatNo', thirdpartyInfo.behalfOfVatNr)
        window.sessionStorage.setItem('thirdPartyInfo', JSON.stringify(thirdpartyInfo))
        context.commit('setThirdpartyInfo', thirdpartyInfo)
    },
    clearThirdPartyInfo(context) {
        //window.sessionStorage.setItem('vatNo', thirdpartyInfo.behalfOfVatNr)
        window.sessionStorage.setItem('thirdPartyInfo', "")
        context.commit('setThirdpartyInfo', {} as AccessInfo)
    }
}

export default actions
